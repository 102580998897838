import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { fetchApi } from "helpers/reactQueryApi";

export type Channel = {
  id: string;
  slug: string;
  title: string;
  description: string;
  status: string;
  image: { _id: string };
  subscribers_count: number;
  is_subscribed: boolean;
};

export default function ChannelRow({
  id,
  slug,
  title,
  description,
  image,
  subscribers_count,
  is_subscribed,
}: Channel) {
  const [isSubscribed, setIsSubscribed] = useState(is_subscribed);
  const [subscribersCount, setSubscribersCount] = useState(subscribers_count);

  const { mutate: subscribeChannel } = useMutation(
    (channelId: string) =>
      fetchApi(`/channels/${channelId}/subscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        setIsSubscribed(true);
        setSubscribersCount(subscribersCount + 1);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  const { mutate: unsubscribeChannel } = useMutation(
    (channelId: string) =>
      fetchApi(`/channels/${channelId}/unsubscribe`, { method: "POST" }),

    {
      onSuccess: () => {
        setIsSubscribed(false);
        setSubscribersCount(subscribersCount - 1);
      },
      onError: (error: any) =>
        toastr.error(error.message || I18n.t("js.generic_error")),
    },
  );

  // to update row on page navigation
  useEffect(() => {
    setIsSubscribed(is_subscribed);
    setSubscribersCount(subscribers_count);
  }, [is_subscribed, subscribers_count]);

  return (
    <div key={id} className="flex gap-3 py-2 items-center">
      <div className="flex justify-center items-center w-16 h-16">
        {image._id ? (
          <img
            className="h-16 w-16"
            src={`/api/storage/images/${image._id}/get`}
          />
        ) : (
          <FontAwesomeIcon
            className="max-h-16 max-w-16 text-gray-400"
            icon={regular("bullhorn")}
            size="2xl"
          />
        )}
      </div>
      <div className="media-body grow">
        <a className="media-heading font-medium" href={`/channels/${slug}`}>
          {title}
        </a>
        <p className="text-muted text-sm">
          {I18n.t("js.directory.channel_row.subscriptions_count", {
            count: subscribersCount,
          })}
        </p>
        <p className="max-w-4xl mt-1 text-sm whitespace-pre-line">
          {description}
        </p>
      </div>
      <div className="">
        <div className="btn-group">
          <button
            className="btn btn-light"
            onClick={(e) => {
              e.preventDefault();
              isSubscribed ? unsubscribeChannel(id) : subscribeChannel(id);
            }}
          >
            {isSubscribed
              ? I18n.t("js.directory.channel_row.unsubscribe.title")
              : I18n.t("js.directory.channel_row.subscribe.title")}
          </button>
        </div>
      </div>
    </div>
  );
}
