import React, { useEffect, useState } from "react";
import { isEmpty, map } from "lodash";
import CheckboxFields from "components/shared/fields/CheckboxFields";
import {
  useFetchDeviceSettings,
  updateDeviceSettings,
} from "components/memberships/api";
import ReduxForm from "components/shared/reduxForm";

export default function PushNotificationSettingsForm() {
  const [deviceId, setDeviceUniqueId] = useState("");
  const [deviceName, setDeviceName] = useState("");

  const { data, refetch } = useFetchDeviceSettings(deviceId);

  function getDeviceUniqueId() {
    window.bridge?.getDeviceUniqueId(({ id, name }) => {
      setDeviceUniqueId(id);
      setDeviceName(name);
    });
  }

  useEffect(() => {
    getDeviceUniqueId();
  });

  useEffect(() => {
    deviceId !== "" && refetch();
  }, [deviceId]);

  function retryDeviceLoading() {
    if (deviceId != "" && isEmpty(data)) {
      window.bridge?.askToRegisterDevice();
    } else {
      getDeviceUniqueId();
    }
  }

  function onSubmit(values, dispatch) {
    dispatch(updateDeviceSettings({ deviceId, notifications: values })).then(
      () => Promise.resolve("Submission successful"),
    );
  }

  return deviceId != "" && !isEmpty(data) ? (
    <ReduxForm
      form="editMemberPushNotificationSettings"
      className={"form-horizontal"}
      initialValues={data?.notifications}
      onSubmit={onSubmit}
      onSubmitSuccess={() => toastr.success(I18n.t("js.saving_successful"))}
      onSubmitFail={() => toastr.error(I18n.t("js.saving_failure"))}
      allowSaveWithoutChange={true}
      onCancel={window.isApp ? () => window.bridge?.goBack() : undefined}
    >
      <div>
        <h3>{I18n.t("js.memberships.settings.push_notifications.tab")}</h3>
        <div
          className="markdown-content prose break-words max-w-prose mb-3"
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              "js.memberships.settings.push_notifications.description",
              { deviceName },
            ),
          }}
        ></div>
        {map(data.notifications, (settings, label) => {
          return (
            <div className="mb-3">
              <CheckboxFields
                key={label}
                name={label}
                label={I18n.t(
                  `js.memberships.settings.push_notifications.${label}.label`,
                )}
                options={map(settings, (setting, key) => ({
                  label: I18n.t(
                    `js.memberships.settings.push_notifications.${label}.${key}`,
                  ),
                  value: key,
                }))}
                multiple
              />
            </div>
          );
        })}
      </div>
    </ReduxForm>
  ) : (
    <div className="flex flex-col">
      <p>{I18n.t("js.memberships.settings.push_notifications.no_settings")}</p>
      <button
        className="btn btn-primary flex justify-center"
        onClick={retryDeviceLoading}
      >
        {I18n.t("js.generic_try_again")}
      </button>
    </div>
  );
}
