import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReduxForm from "components/shared/reduxForm";
import classNames from "classnames";
import { selectNetworkId } from "selectors/environment";
import BasicSettings from "./BasicSettings";
import JoinSettings from "./JoinSettings";
import MiscSettings from "./MiscSettings";

function GroupForm({
  id,
  name,
  group_category_id,
  access,
  sensitive,
  active_main_group,
  can,
  moderated_join,
  members_can_invite,
  chat,
  onSubmit,
  formName,
}) {
  const [activeTab, setActiveTab] = useState("basic");
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `network_${networkId}:group_category_${group_category_id}:group_profile`;
  const externalItemId = id;

  return (
    <ReduxForm
      form={formName}
      initialValues={{
        group_category_id,
        name,
        access,
        moderated_join,
        members_can_invite,
        chat,
      }}
      onSubmit={(values, dispatch, props) =>
        onSubmit(values, dispatch, {
          ...props,
          id,
          externalAppId,
          externalItemId,
        })
      }
      className="form-horizontal"
      renderChildren={({ initialize, initialValues }) => (
        <>
          <ul className="nav nav-tabs">
            <li className={classNames({ active: activeTab === "basic" })}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("basic");
                }}
              >
                {I18n.t("js.groups.edit.basic_settings")}
              </a>
            </li>
            <li className={classNames({ active: activeTab === "join" })}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("join");
                }}
              >
                {I18n.t("js.groups.edit.join_settings")}
              </a>
            </li>
            <li className={classNames({ active: activeTab === "misc" })}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("misc");
                }}
              >
                {I18n.t("js.groups.edit.misc_settings")}
              </a>
            </li>
          </ul>
          <div className="tab-content group-settings">
            <div
              id="#basic_settings"
              className={classNames("tab-pane", {
                active: activeTab === "basic",
              })}
            >
              <BasicSettings
                {...{
                  externalAppId,
                  externalItemId,
                  initialize,
                  initialValues,
                }}
              />
            </div>
            <div
              id="#join_settings"
              className={classNames("tab-pane", {
                active: activeTab === "join",
              })}
            >
              <JoinSettings
                {...{
                  active_main_group,
                  access,
                  sensitive,
                  can,
                  formName,
                }}
              />
            </div>
            <div
              id="#misc_settings"
              className={classNames("tab-pane", {
                active: activeTab === "misc",
              })}
            >
              <MiscSettings chat={chat} />
            </div>
          </div>
        </>
      )}
    />
  );
}

GroupForm.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  group_category_id: PropTypes.string.isRequired,
  active_main_group: PropTypes.bool,
  can: PropTypes.shape({
    create_hidden: PropTypes.bool,
  }),
  access: PropTypes.string,
  sensitive: PropTypes.bool,
  members_can_invite: PropTypes.bool,
  moderated_join: PropTypes.bool,
  profile: PropTypes.shape({
    id: PropTypes.string,
    app_id: PropTypes.string,
    values: PropTypes.shape({
      profile_image: PropTypes.object,
      teaser: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  chat: PropTypes.string,
  formName: PropTypes.string,
  onSubmit: PropTypes.func,
  groupCategoryName: PropTypes.string,
};

export default GroupForm;
