import React, { lazy, Suspense } from "react";
import { RouteObject } from "helpers/tixxt-router";

const ArticleEditPage = lazy(() => import("./ArticleEditPage"));
const ArticleNewPage = lazy(() => import("./ArticleNewPage"));

const articleRoutes: RouteObject[] = [
  {
    path: "/blogs/:blog_id/articles/new",
    element: (
      <Suspense fallback={"Loading..."}>
        <ArticleNewPage />
      </Suspense>
    ),
  },
  {
    path: "/blogs/:blog_id/articles/:id/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <ArticleEditPage />
      </Suspense>
    ),
  },
];

export default articleRoutes;
