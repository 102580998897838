import React from "react";
import { UseFormRegister } from "react-hook-form";
import Label from "components/shared/form/Label";
import PartialToggle from "components/appointments/form/PartialToggle";

type LocationFieldArgs = {
  register: UseFormRegister<any>;
  shouldShowLocationDetails?: boolean;
};
export default function LocationField({
  register,
  shouldShowLocationDetails = false,
}: LocationFieldArgs) {
  return (
    <div className="control-group">
      {<Label label={I18n.t("js.calendars.appointment.location.label")} />}
      <div className="controls border-box flex flex-col p-3 gap-y-3">
        <div className="control-group">
          <Label label={I18n.t("js.calendars.location.name.label")} />
          <div className="controls">
            <input
              type="text"
              placeholder={I18n.t("js.calendars.location.name.placeholder")}
              {...register("location.name")}
            />
          </div>
        </div>
        <PartialToggle
          shouldShowPartial={shouldShowLocationDetails}
          label={I18n.t("js.calendars.location.extend")}
        >
          <div className="control-group">
            <Label label={I18n.t("js.calendars.location.address1.label")} />
            <div className="controls">
              <input
                type="text"
                placeholder={I18n.t(
                  "js.calendars.location.address1.placeholder",
                )}
                {...register("location.address1")}
              />
            </div>
          </div>
          <div className="control-group">
            <Label label={I18n.t("js.calendars.location.address2.label")} />
            <div className="controls">
              <input type="text" {...register("location.address2")} />
            </div>
          </div>
          <div className="control-group">
            <Label
              label={`${I18n.t("js.calendars.location.zip.label")} / ${I18n.t(
                "js.calendars.location.city.label",
              )}`}
            />
            <div className="controls flex gap-2">
              <div className="w-36">
                <input
                  type="text"
                  pattern="[0-9]{5}(-[0-9]{4})?"
                  maxLength={10}
                  placeholder={I18n.t("js.calendars.location.zip.label")}
                  {...register("location.zip")}
                />
              </div>
              <input
                type="text"
                placeholder={I18n.t("js.calendars.location.city.label")}
                {...register("location.city")}
              />
            </div>
          </div>
          <div className="control-group">
            <Label label={I18n.t("js.calendars.location.link.label")} />
            <div className="controls">
              <input
                type="url"
                placeholder={I18n.t("js.calendars.location.link.placeholder")}
                {...register("location.link")}
              />
            </div>
          </div>
        </PartialToggle>
      </div>
    </div>
  );
}
