import React, { useState } from "react";
import { useQuery } from "react-query";
import ToolBar from "components/directory/channels/ToolBar";
import Pagination from "components/shared/Pagination";
import { ceil, isEmpty, map } from "lodash";
import { stringify } from "query-string";
import ChannelRow, { Channel } from "components/directory/channels/ChannelRow";
import { useSearchParams } from "helpers/tixxt-router";

type ChannelData = {
  pagination: {
    total: number;
    page: number;
    per_page: number;
  };
  entries: Channel[];
};

export type Filter = {
  q?: string;
  only_subscribed?: boolean;
};

export default function ChannelListView() {
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [filter, setFilter] = useState<Filter>({
    only_subscribed: searchParams.get("only_subscribed") == "true",
  });

  const { data, isLoading, refetch } = useQuery<ChannelData, Error>(
    `/directory/channels.json?${stringify({
      page: currentPage,
      letter: selectedLetter,
      ...filter,
    })}`,
  );

  function onPaginate(page: number) {
    setCurrentPage(page);
  }

  function onSelectLetter(letter: string) {
    setSelectedLetter(letter);
    setCurrentPage(1);
    setFilter({ only_subscribed: filter.only_subscribed });
    refetch();
  }

  function onSearchQuery(filter: { q?: string; only_subscribed?: boolean }) {
    if (!isEmpty(filter.q)) setSelectedLetter("");
    setCurrentPage(1);
    setFilter(filter);
    refetch();
  }

  return (
    <div className="flex flex-col gap-y-4">
      <ToolBar
        onSelectLetter={onSelectLetter}
        search={onSearchQuery}
        isLoading={isLoading}
        selectedLetter={selectedLetter}
        filter={filter}
      />
      <div className="flex justify-end">
        <Pagination
          page={currentPage}
          totalPages={
            data && ceil(data?.pagination.total / data?.pagination.per_page)
          }
          onPaginate={onPaginate}
          loading={isLoading}
        />
      </div>
      <div className="flex flex-col divide-y divide-neutral media-list">
        {data && !isEmpty(data?.entries)
          ? map(data.entries, (channel) => <ChannelRow {...channel} />)
          : I18n.t("js.directory.empty_collection")}
      </div>
      <div className="flex justify-end">
        <Pagination
          page={currentPage}
          totalPages={
            data && ceil(data?.pagination.total / data?.pagination.per_page)
          }
          onPaginate={onPaginate}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
