import React from "react";
import { isEmpty } from "lodash";
import AppointmentInvite, { AppointmentInviteDate } from "./AppointmentInvite";

type AppointmentParticipationArgs = {
  appointment: {
    id: string;
    name: string;
    status: string;
    date: {
      start: string;
      all_day: boolean;
      same_day: boolean;
      end: string;
    };
    participation_locked: boolean;
  };
  invited_by: {
    id: string;
    name: string;
  };
  status: string;
  inviteStatus: string;
  invitable_type: string;
  id: string;
  reloadInvites: () => void;
};

function AppointmentParticipation({
  appointment,
  invited_by,
  status,
  inviteStatus,
  reloadInvites,
  ...invite
}: AppointmentParticipationArgs) {
  return !isEmpty(appointment) ? (
    <AppointmentInvite
      status={status}
      author={
        !isEmpty(invited_by)
          ? { id: invited_by.id, name: invited_by.name }
          : null
      }
      inviteStatus={inviteStatus}
      invitable={{
        date: appointment.date,
        id: appointment.id,
        name: appointment.name,
        status: appointment.status,
        participation_locked: appointment.participation_locked,
      }}
      renderDate={(props) => <AppointmentInviteDate {...props} />}
      renderMeta={() => null}
      reloadInvites={reloadInvites}
      {...invite}
    />
  ) : null;
}

export default AppointmentParticipation;
