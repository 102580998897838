import { combineReducers } from "redux";
import identity from "lodash/fp/identity";
import get from "lodash/fp/get";
import chats from "./chats";
import messages from "./messages";
import session from "./session";
import composer from "./composer";
import composing from "./composing";
import homies from "./homies";

export const transformPayload = (
  type,
  payloadTransformer,
  defaultState = null,
) => {
  return (state = defaultState, action) => {
    if (action.type !== type) {
      return state;
    }
    return payloadTransformer(action.payload);
  };
};

const chatReducer = combineReducers({
  chats,
  session,
  messages,
  composer,
  profile: transformPayload("chat/MY_PROFILE/REPLY", get("profile")),
  navigation: transformPayload("chat/NAVIGATE", identity, { route: "root" }),
  presence: combineReducers({
    onlineMembers: transformPayload("chat/PRESENCE/SYNC", get("onlineMembers")),
    busyMembers: transformPayload("chat/PRESENCE/SYNC", get("busyMembers")),
  }),
  composing,
  homies,
});

export default chatReducer;
