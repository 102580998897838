import React from "react";
import AppointmentForm from "components/appointments/form/AppointmentForm";
import { useAppointment } from "components/appointments/api";
import { useParams } from "helpers/tixxt-router";
import PageTitle from "components/layout/PageTitle";
import { camelCase, includes, upperFirst } from "lodash";
import CustomConfigurator from "components/../CustomConfigurator";

export default function AppointmentEditPage() {
  const { id } = useParams();
  const { data: appointment, isLoading } = useAppointment(id);

  // appointment types with custom forms: bitkom_campaign
  const FormComponent = includes(
    ["appointment", "recurring_appointment", "virtual_appointment"],
    appointment?.type,
  )
    ? AppointmentForm
    : CustomConfigurator.get(
        `appointments.edit.form.${upperFirst(
          camelCase(appointment?.type),
        )}Form`,
      );

  return (
    <>
      <PageTitle title={appointment?.name || ""} />
      {isLoading
        ? I18n.t("js.loading")
        : FormComponent && <FormComponent appointment={appointment} />}
    </>
  );
}
