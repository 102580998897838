import React, { Ref } from "react";
import { Controller, Control } from "react-hook-form";
import MarkDownEditor from "../../markdownEditor";
import Label from "components/shared/form/Label";
import { omit } from "lodash";

type TextFieldArgs = {
  name: string;
  control: Control<any>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  textEditorRef?: Ref<HTMLDivElement>;
};
export default function TextEditor({
  name,
  control,
  label,
  placeholder = "",
  required = false,
  textEditorRef,
}: TextFieldArgs) {
  return (
    <div className="control-group">
      {label && <Label label={label} required={required} />}
      <div ref={textEditorRef} className="controls">
        <Controller
          name={name}
          control={control}
          rules={{
            required,
          }}
          render={({ field }) => {
            return (
              <MarkDownEditor
                {...omit(field, ["ref"])}
                content={field.value}
                placeholder={placeholder}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
