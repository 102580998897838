import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationOptions,
} from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { Blog } from "components/blogs/types";

export function useBlog(id: string | undefined): UseQueryResult<Blog> {
  return useQuery(`/blogs/${id}`, { enabled: !!id });
}

export function useSaveBlog(
  blog: Blog,
  options:
    | Omit<
        UseMutationOptions<any, { message: string }, { blog: Blog }>,
        "mutationFn"
      >
    | undefined,
) {
  return useMutation(
    (body: { blog: Blog }) =>
      fetchApi(`/blogs/${blog.id}`, { method: "PUT", body }),
    options,
  );
}
