import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import BasicItemForm from "components/appCreator/items/form/BasicItemForm";
import ReduxForm from "components/shared/reduxForm";
import PageTitle from "components/layout/PageTitle";

function MembershipForm({
  externalAppId,
  externalItemId,
  onSubmit,
  title,
  renderExtraFields = noop,
  initialValues,
  allowSaveWithoutChange,
  filterProperties,
  mapProperties,
  transformItem,
}) {
  return (
    <Fragment>
      <PageTitle title={title} />
      <ReduxForm
        form="editMembership"
        className={"form-horizontal"}
        initialValues={initialValues}
        renderChildren={({ initialize, initialValues }) => (
          <Fragment>
            {renderExtraFields()}
            <BasicItemForm
              externalAppId={externalAppId}
              externalItemId={externalItemId}
              initialize={(itemValues, options) =>
                initialize({ ...initialValues, ...itemValues }, options)
              }
              filterProperties={filterProperties}
              mapProperties={mapProperties}
              transformItem={transformItem}
            />
          </Fragment>
        )}
        onSubmit={onSubmit}
        onCancel={window.isApp ? () => window.bridge?.goBack() : null}
        allowSaveWithoutChange={allowSaveWithoutChange}
      />
    </Fragment>
  );
}

MembershipForm.propTypes = {
  externalAppId: PropTypes.string,
  externalItemId: PropTypes.string,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  title: PropTypes.string,
  renderExtraFields: PropTypes.func,
  initialValues: PropTypes.object,
  allowSaveWithoutChange: PropTypes.bool,
  filterProperties: PropTypes.func,
  mapProperties: PropTypes.func,
  transformItem: PropTypes.func,
};

export default MembershipForm;
