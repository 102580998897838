import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import classNames from "classnames";
import { some } from "lodash";
import { useNavigate } from "helpers/tixxt-router";

type ToolbarArgs = {
  id: string;
  can: { [key: string]: boolean };
  participationLocked: boolean;
  participantsEmpty: boolean;
  isRecurring: boolean;
  name: string;
  type: string;
  canceled: boolean;
  refetch: () => void;
  isModifiedRecurring?: boolean;
  previousOccurrenceUrl?: string;
  recurringAppointmentUrl?: string;
  nextOccurrenceUrl?: string;
  cancelProhibitedReason?: string;
};

export default function Toolbar({
  id,
  can,
  participationLocked,
  participantsEmpty,
  cancelProhibitedReason,
  isRecurring,
  name,
  isModifiedRecurring,
  previousOccurrenceUrl,
  recurringAppointmentUrl,
  nextOccurrenceUrl,
  refetch,
  type,
  canceled,
}: ToolbarArgs) {
  const navigate = useNavigate();
  const { mutate: toggleLockParticipation } = useMutation(
    () =>
      fetchApi(`/appointments/${id}`, {
        method: "PATCH",
        body: {
          appointment: {
            attendee_configuration: {
              participation_locked: !participationLocked,
            },
          },
        },
      }),
    {
      onSuccess: () => {
        toastr.success(
          I18n.t(
            `js.calendars.appointment.attendee_configuration.request.${
              participationLocked ? "unlocked" : "locked"
            }.success`,
          ),
        );
        refetch();
      },
      onError: () => {
        toastr.error(
          I18n.t(
            "js.calendars.appointment.attendee_configuration.request.failure",
          ),
        );
      },
    },
  );

  const { mutate: cancel } = useMutation(() =>
    fetchApi(`/appointments/${id}/cancel`, {
      method: "POST",
    }),
  );

  const { mutate: destroy } = useMutation(
    () =>
      fetchApi(`/appointments/${id}`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        toastr.success(I18n.t("js.calendars.appointment.delete_success"));
        navigate("/appointments");
      },
    },
  );

  const showContextMenu = some(
    [
      "update",
      "contact",
      "update_participants",
      "download_attendees",
      "update_participants",
      "destroy",
    ],
    (key) => can[key],
  );

  return (
    <div className="btn-toolbar mb-10">
      <button
        className="btn btn-light btn-sm"
        onClick={(e) => {
          e.preventDefault();
          navigate("/appointments");
        }}
      >
        <FontAwesomeIcon
          className="text-primary"
          icon={regular("arrow-left")}
        />
      </button>
      {isModifiedRecurring && (
        <div>
          {previousOccurrenceUrl && (
            <a
              className="btn"
              href={previousOccurrenceUrl}
              title={I18n.t(
                "js.calendars.recurring_appointment.previous_occurrence_tooltip",
              )}
            >
              <FontAwesomeIcon icon={solid("caret-left")} />
            </a>
          )}
          {recurringAppointmentUrl && (
            <a className="btn btn-primary" href={recurringAppointmentUrl}>
              <FontAwesomeIcon
                icon={regular("arrows-repeat")}
                className="mr-1"
              />
              {I18n.t(
                "js.calendars.recurring_appointment.show_recurring_button",
              )}
            </a>
          )}
          {nextOccurrenceUrl && (
            <a
              className="btn"
              href={nextOccurrenceUrl}
              title={I18n.t(
                "js.calendars.recurring_appointment.next_occurrence_tooltip",
              )}
            >
              <FontAwesomeIcon icon={solid("caret-right")} />
            </a>
          )}
        </div>
      )}
      <div className={classNames({ "btn-group": showContextMenu })}>
        <a
          className="btn btn-primary"
          href={`${id}.ics`}
          title={I18n.t("js.calendars.appointment.ical_download")}
        >
          {I18n.t("js.calendars.appointment.ical_download_button")}
        </a>
        {showContextMenu && (
          <button
            className="btn btn-light dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <FontAwesomeIcon icon={regular("ellipsis-h")} />
          </button>
        )}

        <DropDown className="context-menu dropdown-menu">
          {can.update && (
            <>
              <DropDownItem
                icon={regular("pencil")}
                iconClassName="text-muted"
                text={I18n.t("js.calendars.appointment.edit")}
                url={`${id}/edit`}
              />
              <li className="divider" />
            </>
          )}
          {can.contact && (
            <>
              <DropDownItem
                url={`${id}/contact`}
                icon={regular("envelope")}
                iconClassName="text-muted"
                text={I18n.t(
                  "js.calendars.appointment.attendees.contact.title",
                )}
              />
              <li className="divider" />
            </>
          )}
          {can.update_participants && (
            <DropDownItem
              url={`${id}/participants`}
              icon={regular("users")}
              iconClassName="text-muted"
              text={I18n.t("js.calendars.appointment.participations.manage")}
            />
          )}
          {can.update && type !== "recurring_appointment" && (
            <DropDownItem
              onSelect={(e) => {
                e.preventDefault();
                toggleLockParticipation();
              }}
              icon={participationLocked ? regular("unlock") : regular("lock")}
              iconClassName="text-muted"
              text={
                participationLocked
                  ? I18n.t(
                      "js.calendars.appointment.attendee_configuration.participation_unlock",
                    )
                  : I18n.t(
                      "js.calendars.appointment.attendee_configuration.participation_lock",
                    )
              }
            />
          )}
          {can.download_attendees && !participantsEmpty && (
            <>
              <DropDownItem
                url={`${id}/attendees.csv`}
                icon={regular("file-csv")}
                iconClassName="text-muted"
                text={I18n.t("js.calendars.appointment.invitees.download_as", {
                  file_ending: "CSV",
                })}
              />
              <DropDownItem
                url={`${id}/attendees.xlsx`}
                icon={regular("file-excel")}
                iconClassName="text-muted"
                text={I18n.t("js.calendars.appointment.invitees.download_as", {
                  file_ending: "XLSX",
                })}
              />
            </>
          )}
          {can.update_participants && !participantsEmpty && (
            <li className="divider" />
          )}
          {can.destroy && (
            <>
              <DropDownItem
                onSelect={(e) => {
                  e.preventDefault();
                  !canceled &&
                    window.bridge?.confirm(
                      I18n.t(
                        isRecurring
                          ? "calendars.recurring_appointment.confirm_cancel"
                          : "calendars.appointment.confirm_cancel",
                        { appointment_name: name },
                      ),
                      () => cancel(),
                    );
                }}
                icon={regular("ban")}
                linkStyle={classNames({
                  "pointer-events-none disabled": canceled,
                })}
                iconClassName="text-muted"
                text={I18n.t("js.calendars.appointment.cancel")}
                title={cancelProhibitedReason}
              />
              <li className="divider" />
            </>
          )}
          {can.destroy && (
            <DropDownItem
              onSelect={(e) => {
                e.preventDefault();
                window.bridge?.confirm(
                  I18n.t(
                    isRecurring
                      ? "calendars.recurring_appointment.confirm_destroy"
                      : "calendars.appointment.confirm_destroy",
                    { appointment_name: name },
                  ),
                  () => destroy(),
                );
              }}
              icon={regular("trash")}
              linkStyle="!text-danger"
              iconClassName="text-danger"
              text={I18n.t("js.calendars.appointment.delete")}
            />
          )}
        </DropDown>
      </div>
    </div>
  );
}
