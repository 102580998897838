import React from "react";
import { useStore, useSelector } from "react-redux";
import { get } from "lodash";
import MembershipForm from "components/memberships/MembershipForm";
import persistItem from "helpers/items/persistItem";
import { selectNetworkId } from "selectors/environment";
import ControlGroup from "../shared/fields/ControlGroup";
import SimpleInput from "components/appCreator/items/form/SimpleInput";
import { useParams } from "helpers/tixxt-router";
import { useMembershipProfile } from "./api";
import { useLoadApp } from "components/appCreator/api";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

type MemberEditFieldsProps = {
  membershipId?: string;
};

function MemberEditFields({ membershipId }: MemberEditFieldsProps) {
  const { data: membership, isLoading: loading } =
    useMembershipProfile(membershipId);

  return (
    <ControlGroup
      name="membership_email"
      label={I18n.t("js.administration.members.new.email")}
      htmlFor="membership-email"
    >
      {loading ? (
        <i className="fa fa-spin fa-spinner" />
      ) : (
        <SimpleInput
          meta={{}}
          input={{
            type: "text",
            name: "membership_email",
            id: "membership-email",
            value: get(membership, ["email"]),
            disabled: true,
          }}
        />
      )}
    </ControlGroup>
  );
}

function MembershipEdit() {
  const { membershipId } = useParams();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const externalItemId = membershipId;
  const store = useStore();
  const { data: app } = useLoadApp({
    appId: undefined,
    externalAppId,
  });

  const { mutate: updateMembership } = useMutation(
    ({ membershipId }: { membershipId?: string }) =>
      fetchApi(`/administration/members/${membershipId}`, {
        method: "PUT",
      }),
    {
      onSuccess: () => {
        location.replace(`/administration/members`);
        toastr.success(I18n.t("js.saving_successful"));
      },
      onError: () => {
        toastr.error(I18n.t("js.saving_failure"));
      },
    },
  );

  const onSubmit = async (values) => {
    await persistItem(app, store.dispatch, {
      externalAppId,
      externalItemId,
      values,
    });

    updateMembership({ membershipId });
  };

  return (
    <MembershipForm
      key={membershipId}
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      renderExtraFields={() => <MemberEditFields membershipId={membershipId} />}
      title={I18n.t("js.administration.members.edit.page_title")}
    />
  );
}

export default MembershipEdit;
