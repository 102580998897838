import React from "react";
import { map, isEmpty } from "lodash";
import AppointmentInvite, {
  AppointmentInviteDate,
  AppointmentInviteMeta,
} from "components/appointments/AppointmentInvite";
import RecurringAppointmentInvite from "components/appointments/RecurringAppointmentInvite";
import AppointmentParticipation from "components/appointments/AppointmentParticipation";
import CustomConfigurator from "../../CustomConfigurator";

if (!CustomConfigurator.existsDefault("invites.types")) {
  CustomConfigurator.setDefault("invites.types", {
    appointments: {
      invites: {
        Appointment: AppointmentInvite,
        ModifiedRecurringAppointment: RecurringAppointmentInvite,
        RecurringAppointment: RecurringAppointmentInvite,
      },
      participations: {
        default: AppointmentParticipation,
      },
    },
  });
}

type InvitesListArgs = {
  inviteType: string;
  invites: {
    type: string;
    data: { invitable_type: string; _type: string; id: string }[];
  };
  inviteStatus: string;
  loadMore: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  canLoadMore: boolean;
  isLoading: boolean;
  reloadInvites: () => void;
};
export default function InvitesList({
  invites,
  inviteType,
  inviteStatus,
  isLoading,
  loadMore,
  canLoadMore,
  reloadInvites,
}: InvitesListArgs) {
  return (
    <div>
      {isLoading ? (
        <div className="mt-2">
          {I18n.t("js.calendars.appointments.invites.loading")}
        </div>
      ) : !isEmpty(invites.data) && !isLoading ? (
        <div className="media-list">
          {map(invites.data, (invite, index) => {
            const path = [
              "invites",
              "types",
              inviteType,
              invites.type,
              (invites.type === "invites"
                ? invite.invitable_type
                : invite._type) || "default",
            ];
            const Component =
              CustomConfigurator.get(path) ||
              (() => `Missing component for ${path.join(".")}`);

            return (
              <Component
                inviteStatus={inviteStatus}
                key={index}
                reloadInvites={reloadInvites}
                renderDate={(props) => <AppointmentInviteDate {...props} />}
                renderMeta={(props) => <AppointmentInviteMeta {...props} />}
                {...invite}
              />
            );
          })}
        </div>
      ) : (
        <div className="alert alert-info mt-4">
          {I18n.t(`js.calendars.appointments.invites.no_${inviteStatus}`)}
        </div>
      )}
      {canLoadMore && !isLoading ? (
        <div className="appointment-invites-show-more-container">
          <a className="btn" onClick={loadMore} href="#">
            <i className="fa fa-arrow-down" />{" "}
            {I18n.t("js.calendars.appointments.invites.show_more")}
          </a>
        </div>
      ) : null}
    </div>
  );
}
