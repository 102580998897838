import { forEach } from "lodash";

export function onValidationError(errors) {
  resetValidationErrors();
  forEach(errors, (error) => {
    error?.ref?.classList.add("field-error");

    toastr.error(error?.message || I18n.t("generic_error"));
  });
}

export function resetValidationErrors() {
  forEach(document.getElementsByClassName("field-error"), (element) => {
    element?.classList.remove("field-error");
  });
}
