import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

// Connects to data-controller="shared--password-toggle"
export default class extends Controller {
  connect() {
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.addToggleIcons();
  }

  addToggleIcons() {
    const passwordFields = this.element.querySelectorAll(
      'input[type="password"]',
    );
    passwordFields.forEach((passwordField) => {
      const icon = this.#createVisibilityIcon("fa-eye");
      passwordField.insertAdjacentElement("afterend", icon);
    });
  }

  toggleVisibility = debounce(function (event) {
    let icon = null;

    if (event.target.nodeName === "svg") {
      icon = event.target.parentElement;
    } else {
      icon = event.target.parentElement.parentElement;
    }

    const passwordField = icon.previousElementSibling;

    if (passwordField && passwordField.type === "password") {
      passwordField.type = "text";
      const newIcon = this.#createVisibilityIcon("fa-eye-slash");
      icon.parentElement.replaceChild(newIcon, icon);
    } else if (passwordField) {
      passwordField.type = "password";
      const newIcon = this.#createVisibilityIcon("fa-eye");
      icon.parentElement.replaceChild(newIcon, icon);
    }
  }, 1);

  #createVisibilityIcon(style) {
    const icon = document.createElement("i");
    icon.classList.add(
      "fa-regular",
      style,
      "absolute",
      "right-2",
      "top-2",
      "cursor-pointer",
    );
    icon.dataset.action = "click->shared--password-toggle#toggleVisibility";
    return icon;
  }
}
