import { useQuery } from "react-query";

type ChannelPayload = {
  id: string;
  slug: string;
  title: string;
  description: string;
  image: {
    id: string;
    name: string;
    content_type: string;
    image_url: string;
    small_thumb: string;
    large_thumb: string;
    size: number;
    extension: string;
    type: string;
  } | null;
  status: string;
  i: {
    can: { [rule: string]: boolean | undefined };
    subscribed: boolean;
    favorited: boolean;
  };
  blog_id: string;
};

export function useChannels() {
  return useQuery<{
    subscribed: ChannelPayload[];
    recent: ChannelPayload[];
    popular: ChannelPayload[];
  }>("/channels", {
    refetchOnWindowFocus: false,
    initialData: { subscribed: [], recent: [], popular: [] },
  });
}

export function useChannel(slug: string | undefined) {
  return useQuery<ChannelPayload>(`/channels/${slug}`, { enabled: !!slug });
}
