import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { compose, withHandlers } from "recompose";
import {
  isEmpty,
  map,
  each,
  castArray,
  compact,
  get,
  last,
  zip,
  isMatch,
  groupBy,
  sum,
} from "lodash";
import { v4 as uuidv4 } from "uuid";

import withDragDropContext from "components/shared/withDragDropContext";
import ImagePreview from "./fileUploadField/ImagePreview";
import FileInput from "./fileUploadField/FileInput";
import UploadedFile from "./fileUploadField/UploadedFile";
import FieldError from "components/appCreator/items/form/FieldError";
import { uploadFile } from "actions/files";
import UploadBar from "./fileUploadField/UploadBar";

// Use this with redux-form. For use with react-hook-form you need components/shared/form/fields/FileUpload.tsx
const FileUploadField = ({
  multiple,
  onRemoveFile,
  storageDirectory = "files",
  input,
  meta,
  isImage,
  selectExisting,
  accept,
}) => {
  const dispatch = useDispatch();
  const files = compact(castArray(input.value));

  // Queues given files for uploading.
  // Discards all but the first file if it is non-multiple
  // and displays warning to the user.
  const uploadFiles = (rawFiles) => {
    const filesData = map(rawFiles, (file) => ({
      name: file.name,
      type: file.type,
      extension: last(file.name.split(".")),
      clientId: uuidv4(),
      progress: 0,
      state: "uploading",
    }));
    dispatch(change(meta.form, input.name, [...files, ...filesData]));

    each(zip(rawFiles, filesData), ([file, { clientId }]) => {
      dispatch(
        uploadFile({
          form: meta.form,
          inputName: input.name,
          clientId,
          file,
          storageDirectory,
        }),
      );
    });
  };

  const onSelectExistingFile = (selectedFiles) => {
    dispatch(change(meta.form, input.name, [...files, ...selectedFiles]));
  };

  const filesByState = {
    published: [],
    volatile: [],
    uploading: [],
    failed: [],
    ...groupBy(files, "state"),
  };

  const availableFiles = [...filesByState.published, ...filesByState.volatile];
  const uploadingFiles = [...filesByState.volatile, ...filesByState.uploading];
  const nonRemovedFiles = [
    ...filesByState.published,
    ...filesByState.volatile,
    ...filesByState.failed,
    ...filesByState.uploading,
  ];

  return (
    <>
      <div className="fileUploadField border-box p-3 flex items-stretch gap-2 mt-2">
        {isImage && !multiple ? (
          <ImagePreview
            imageId={get(availableFiles, [0, "id"])}
            storageDirectory={storageDirectory}
          />
        ) : null}
        <FileInput
          isImage={isImage}
          acceptedType={
            accept ||
            (storageDirectory === "images" || storageDirectory === "assets"
              ? "image/*"
              : "*")
          }
          storageDirectory={storageDirectory}
          multiple={multiple}
          empty={isEmpty(availableFiles)}
          onSelectFile={(e) => uploadFiles(e.target.files)}
          selectExisting={!isImage && selectExisting}
          onSelectExistingFile={onSelectExistingFile}
          onDropFile={({ files }) => uploadFiles(files)}
          {...input}
        />
      </div>
      <div className="upload-states-view flex flex-col gap-px py-px">
        {uploadingFiles.length > 1 ? (
          <div className="upload-status">
            <div className="upload-progress">
              <UploadBar
                progress={
                  sum(map(uploadingFiles, "progress")) / uploadingFiles.length
                }
                state="uploading"
              />
            </div>
            <div className="upload-controls">
              <div>
                <span className="text-sm">
                  {I18n.t("js.files.uploader.total_progress", {
                    completed: filesByState.volatile.length,
                    total: uploadingFiles.length,
                  })}
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {map(nonRemovedFiles, (file, key) => (
          <UploadedFile
            key={`${file.external_id || file.id}-${key}`}
            file={file}
            remove={onRemoveFile}
          />
        ))}
      </div>
      {meta.submitFailed && meta.error && <FieldError error={meta.error} />}
    </>
  );
};
const valuePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  progress: PropTypes.number,
  state: PropTypes.string,
  external_id: PropTypes.string,
  type: PropTypes.string,
});
FileUploadField.propTypes = {
  multiple: PropTypes.bool,
  storageDirectory: PropTypes.string,
  isImage: PropTypes.bool,
  onSelectFile: PropTypes.func,
  onDropFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
  selectExisting: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(valuePropType),
      valuePropType,
      PropTypes.any,
    ]),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    form: PropTypes.string,
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
  className: PropTypes.string,
};

export default compose(
  withDragDropContext,
  withHandlers({
    onRemoveFile:
      ({ input }) =>
      (criteria) => {
        window.bridge.confirm(I18n.t("js.files.file.delete_confirm"), () => {
          const files = compact(castArray(input.value));
          input.onChange(
            // Marks files to be removed in publish phase
            // removes failed uploads immediately
            compact(
              map(files, (f) =>
                isMatch(f, criteria)
                  ? f.state === "failed"
                    ? null
                    : { ...f, state: "removed" }
                  : f,
              ),
            ),
          );
        });
      },
  }),
)(FileUploadField);
