import React, { Fragment, useState, useEffect } from "react";
import {
  isEmpty,
  map,
  pickBy,
  keys,
  get,
  cloneDeep,
  snakeCase,
  compact,
  includes,
  filter,
} from "lodash";
import { useParams } from "helpers/tixxt-router";
import { useMutation, useQuery } from "react-query";
import Loading from "components/shared/Loading";
import classNames from "classnames";

import ReduxForm from "components/shared/reduxForm";
import PageTitle from "components/layout/PageTitle";
import DigestSettings from "components/memberships/membershipSettings/DigestSettings";
import EmailConfigList from "components/memberships/membershipSettings/EmailConfigList";
import PushNotificationSettingsForm from "components/memberships/PushNotificationSettingsForm";
import { fetchApi } from "helpers/reactQueryApi";

const transform2FrontendState = (state) => {
  const newState = cloneDeep(state);
  map(["email_digests", "email_daily_digests"], (key) => {
    if (newState[key].enabled) {
      if (newState[key].group_ids === "all") {
        newState[key].switch = "all";
      } else {
        newState[key].switch = "selected_groups";
      }
    } else {
      newState[key].switch = "disabled";
    }
  });

  return newState;
};

const transform2BackendState = (state) => {
  const newState = cloneDeep(state);
  map(["email_digests", "email_daily_digests"], (key) => {
    switch (newState[key].switch) {
      case "all":
        newState[key].enabled = true;
        newState[key].group_ids = ["all"];
        break;
      case "selected_groups":
        newState[key].enabled = true;
        break;
      case "disabled":
        newState[key].enabled = false;
        break;
    }
  });
  map(
    ["email_subscriptions", "email_notifications", "email_admin_notifications"],
    (key) => {
      newState[key] = keys(pickBy(newState[key]));
    },
  );

  return newState;
};

export default function EditMembershipSettings() {
  const { membershipId } = useParams();

  const { isLoading: loading, data } = useQuery(
    `/members/${membershipId}/settings`,
  );

  const { mutate: updateMembershipSettings } = useMutation(
    ({ body }: { [key: string]: boolean }) =>
      fetchApi(`/members/${membershipId}/settings`, {
        method: "PUT",
        body,
      }),
    {
      onSuccess: () => toastr.success(I18n.t("js.saving_successful")),
      onError: () => {
        toastr.error(I18n.t("js.saving_failure"));
      },
    },
  );

  const { settings, conditions, disabled } = (data || {}) as any;

  const showEmailDigests =
    get(conditions, ["email_digests", "enabled"], true) &&
    get(conditions, ["email_digests", "set"], true);

  const showEmailDailyDigests = get(
    conditions,
    ["email_daily_digests", "enabled"],
    true,
  );

  useEffect(() => {
    if (!showEmailDigests && !showEmailDailyDigests) {
      setActiveTab("email-subscriptions");
    }
  }, [showEmailDigests, showEmailDailyDigests]);

  const [activeTab, setActiveTab] = useState("email-digests");

  const notificationsDeactivated = get(
    conditions,
    ["email_notifications_disabled"],
    false,
  );

  const onSubmit = async (values) => {
    updateMembershipSettings({
      body: transform2BackendState(values),
    });

    if (notificationsDeactivated) location.reload();
  };

  const styles = {
    overflow: "visible",
    marginBottom: "18px",
  };

  let tabs = [
    "email-digests",
    "email-daily-digests",
    "email-subscriptions",
    "email-notifications",
    "email-newsletter",
    "email-admin-notifications",
    "push-notifications",
  ];

  tabs = compact(
    map(tabs, (tab) => {
      if (validateTab(tab)) return tab;
    }),
  );

  function validateTab(tab) {
    switch (tab) {
      case "email-digests":
        return showEmailDigests;
      case "email-daily-digests":
        return showEmailDailyDigests;
      case "email-newsletter":
        return get(conditions, ["email_newsletter", "enabled"], false);
      case "email-admin-notifications":
        return get(conditions, ["email_admin_notifications", "enabled"], true);
      case "push-notifications":
        return window.isApp;
      default:
        return true;
    }
  }

  if (loading || !data) return <Loading />;

  return (
    <Fragment>
      <PageTitle title={I18n.t("js.memberships.settings.title")} />
      {loading || isEmpty(settings) ? (
        <div>
          <i className="fa fa-spinner fa-spin" />
          {I18n.t("js.loading")}
        </div>
      ) : (
        <div>
          <ul className="nav nav-tabs">
            {/**** Header ****/}
            {notificationsDeactivated ? (
              <p className="text-sm">
                {I18n.t(
                  "js.memberships.settings.email_notifications.bouncelist_hint",
                )}
              </p>
            ) : (
              map(tabs, (tab, index) => (
                <li
                  key={index}
                  className={classNames({
                    active: activeTab === tab,
                  })}
                >
                  <a
                    href={`#${tab}`}
                    data-toggle="tab"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(tab);
                    }}
                  >
                    {I18n.t(`js.memberships.settings.${snakeCase(tab)}.tab`)}
                  </a>
                </li>
              ))
            )}
          </ul>
          {activeTab != "push-notifications" && (
            <ReduxForm
              form="editMembershipSettings"
              className={"form-horizontal"}
              initialValues={transform2FrontendState(settings)}
              onSubmit={onSubmit}
              submitText={
                notificationsDeactivated
                  ? I18n.t(
                      "js.memberships.settings.email_notifications.activate",
                    )
                  : undefined
              }
              allowSaveWithoutChange={notificationsDeactivated}
              onCancel={
                window.isApp ? () => window.bridge?.goBack() : undefined
              }
            >
              {notificationsDeactivated ? (
                <p className="text-sm">
                  {I18n.t(
                    "js.memberships.settings.email_notifications.bouncelist_hint",
                  )}
                </p>
              ) : (
                <div style={styles} className="tab-content mt-4">
                  {/**** Content ****/}
                  {map(
                    filter(tabs, (t) => t !== "push-notifications"),
                    (tab) => (
                      <div
                        key={tab}
                        id={tab}
                        className={classNames("tab-pane", {
                          active: activeTab === tab,
                        })}
                      >
                        {includes(
                          ["email-digests", "email-daily-digests"],
                          tab,
                        ) ? (
                          <DigestSettings
                            conditions={conditions}
                            disabled={disabled}
                            configCategory={snakeCase(tab)}
                          />
                        ) : (
                          <EmailConfigList
                            conditions={conditions}
                            disabled={disabled}
                            settings={settings}
                            configCategory={snakeCase(tab)}
                          />
                        )}
                      </div>
                    ),
                  )}
                </div>
              )}
            </ReduxForm>
          )}
          {window.isApp && activeTab == "push-notifications" && (
            <div style={styles} className="tab-content mt-4">
              <div
                id="push-notifications"
                className={classNames("tab-pane", {
                  active: activeTab === "push-notifications",
                })}
              >
                <PushNotificationSettingsForm />
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
