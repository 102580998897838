import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { split } from "lodash";

export default function ModifiedRecurringAppointmentHint({
  appointmentId,
}: {
  appointmentId: string;
}) {
  const recurringAppointmentId = split(appointmentId, "-")[1];
  return (
    <div className="alert alert-info grid grid-rows-[1fr_auto] grid-cols-1 sm:grid-rows-1 sm:grid-cols-[1fr_auto] gap-2 items-start">
      <div
        className="max-w-prose text-sm prose"
        dangerouslySetInnerHTML={{
          __html: I18n.t("js.calendars.appointment.recurrence-pattern.hint"),
        }}
      />
      <a
        href={`/appointments/${recurringAppointmentId}/edit`}
        className="btn btn-primary flex gap-2 justify-center items-center"
      >
        <FontAwesomeIcon icon={regular("arrows-repeat")} />
        {I18n.t("js.calendars.appointment.recurrence-pattern.edit_series")}
      </a>
    </div>
  );
}
