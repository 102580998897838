import React, { useRef } from "react";
import { reduce, map, forEach, defer, keys } from "lodash";

import { useCreateAppointmentContact } from "./api";
import { Appointment } from "../../@types/appointments";
import { useNavigate } from "helpers/tixxt-router";
import { useForm } from "react-hook-form";
import TextInput from "components/shared/form/fields/TextInput";
import Hint from "components/shared/form/fields/Hint";
import TextEditor from "components/shared/form/fields/TextEditor";
import Select from "components/shared/form/fields/Select";
import Checkbox from "components/shared/form/fields/Checkbox";
import Label from "components/shared/form/Label";
import FormActions from "components/shared/form/fields/FormActions";

type ContactArgs = {
  appointment: Appointment;
};

type FormData = {
  audience: {
    label: string;
    value: "all" | "accepted" | "declined" | "no_reaction";
  };
  subject: string;
  text: string;
  include_attachments: boolean;
};

export default function Contact({ appointment }: ContactArgs) {
  const navigate = useNavigate();
  const { handleSubmit, register, control } = useForm<FormData>({
    defaultValues: {
      audience: {
        label: I18n.t(
          "js.calendars.appointment.attendees.contact.audience.all",
        ),
        value: "all",
      },
    },
  });

  const textEditorRef = useRef<HTMLDivElement | null>(null);

  const attachmentSize = reduce(
    map(appointment.event_files, (file) => file.size),
    (sum: any, size: any) => sum + size,
    0,
  );
  const hasAttachments = attachmentSize > 0;
  const areAttachmentsInLimit =
    attachmentSize <=
    Preload.current_network.config.email_recommendations.attachment_size_limit;

  const { mutate: createAppointmentContact, isLoading } =
    useCreateAppointmentContact({
      onSuccess: () => {
        toastr.success(I18n.t("js.saving_successful"));
        navigate(`/appointments/${appointment.id}`);
      },
      onError: () => {
        toastr.error(I18n.t("js.generic_error"));
      },
    });

  const submit = (values: FormData) => {
    return createAppointmentContact({
      appointmentId: appointment.id,
      contact: { ...values, audience: values.audience.value },
    });
  };

  const audienceOptions = map(
    ["all", "accepted", "declined", "no_reaction"],
    (opt) => ({
      value: opt,
      label: I18n.t(
        `js.calendars.appointment.attendees.contact.audience.${opt}`,
      ),
    }),
  );

  function onValidationError(errors) {
    forEach(document.getElementsByClassName("field-error"), (element) => {
      element?.classList.remove("field-error");
    });

    forEach(errors, (error, name) => {
      if (name === "text") {
        defer(() => {
          textEditorRef?.current?.classList.add("field-error");
          if (keys(errors)[0] === "text")
            textEditorRef?.current
              ?.querySelector<HTMLDivElement>("[contenteditable]")
              ?.focus();
        });
      } else {
        error?.ref?.classList.add("field-error");
      }
      toastr.error(I18n.t("generic_error"));
    });
  }

  return (
    <form
      className="form-horizontal mb-6"
      onSubmit={handleSubmit(submit, onValidationError)}
    >
      <Hint
        text={I18n.t("js.calendars.appointment.attendees.contact.general_help")}
        usabilityHint
      />
      <Select
        name="audience"
        label={I18n.t(
          "js.calendars.appointment.attendees.contact.audience.label",
        )}
        options={audienceOptions}
        control={control}
      />
      <TextInput
        label={I18n.t("js.calendars.appointment.attendees.contact.subject")}
        name="subject"
        register={register}
        placeholder={I18n.t("js.calendars.appointment.name.placeholder")}
        required
      />
      <TextEditor
        name="text"
        control={control}
        label={I18n.t("js.calendars.appointment.attendees.contact.text")}
        textEditorRef={textEditorRef}
        required
      />
      {hasAttachments && (
        <div className="control-group">
          <Label
            label={I18n.t(
              "js.calendars.appointment.attendees.contact.attachments",
            )}
          />
          <div className="controls border-box p-3">
            <Checkbox
              name="include_attachments"
              register={register}
              label={I18n.t(
                "js.calendars.appointment.attendees.contact.include_attachments",
              )}
              disabled={!areAttachmentsInLimit}
            />
            {!areAttachmentsInLimit && (
              <Hint
                text={I18n.t(
                  "js.activitystreams.email_recommendations.attachments_too_large",
                  {
                    limit: `${
                      Preload.current_network.config.email_recommendations
                        .attachment_size_limit /
                      1024 /
                      1024
                    }MB`,
                  },
                )}
              />
            )}
          </div>
        </div>
      )}
      <FormActions
        onCancel={(e) => {
          e.preventDefault();
          navigate(`/appointments/${appointment.id}`);
        }}
        saveDisabled={isLoading}
      />
    </form>
  );
}
