import React from "react";

type TranslationArgs = {
  component?: React.ElementType;
  translation: string;
  variables: { [key: string]: string };
};

export default function Translation({
  component: Component = "div",
  translation,
  variables,
}: TranslationArgs) {
  const isHtml = translation.endsWith("_html");
  const isMarkdown = translation.endsWith("_md");

  return isHtml || isMarkdown ? (
    <Component
      dangerouslySetInnerHTML={{
        __html: isMarkdown
          ? Application.markdown(I18n.t(translation, variables))
          : I18n.t(translation, variables),
      }}
    />
  ) : (
    <Component>{I18n.t(translation, variables)}</Component>
  );
}
