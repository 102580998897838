import React from "react";
import { map, times } from "lodash";
import Loading from "components/shared/Loading";
import classNames from "classnames";

type LayoutData = {
  rows: {
    id: string;
    type: string;
  }[];
};

type ChannelLayoutProps = {
  layoutData: LayoutData | undefined;
  isLoading: boolean;
};

const SPAN_LOOK_UP = {
  full: ["sm:col-span-3"],
  "half-1-2": ["sm:col-span-1", "sm:col-span-2"],
  "half-2-1": ["sm:col-span-2", "sm:col-span-1"],
  third: times(3, () => "sm:col-span-1"),
};

export const ChannelLayout: React.FC<ChannelLayoutProps> = ({
  layoutData,
  isLoading,
}) => {
  if (isLoading || !layoutData) return <Loading />;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      {map(layoutData.rows, (item) => {
        const spansForRow = SPAN_LOOK_UP[item.type];
        return map(spansForRow, (span, index) => (
          <div
            key={index}
            className={classNames(
              `
            bg-gray-200 rounded
            flex justify-center items-center
            col-span-1
            ${span}
            sm:h-96
          `,
              {
                "h-96": spansForRow.length === 3,
                "h-[48rem]":
                  spansForRow.length === 1 || spansForRow.length === 2,
              },
            )}
          >
            {item.id}
          </div>
        ));
      })}
    </div>
  );
};
