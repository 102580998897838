import React from "react";
import { useSelector, useStore } from "react-redux";
import { get, isEmpty } from "lodash";
import { useParams } from "helpers/tixxt-router";
import GroupForm from "./form";
import EditGroupActions from "./EditGroupActions";
import persistItem from "helpers/items/persistItem";
import TixxtPageTitle from "../layout/TixxtPageTitle";
import { updateGroup, fetchGroup } from "./api";
import { useMutation, useQuery } from "react-query";
import { selectNetworkId } from "../../selectors/environment";
import { useLoadApp } from "../appCreator/api";

export default function EditGroup() {
  const { groupSlug } = useParams();

  const { data: group } = useQuery(["group", groupSlug], () =>
    fetchGroup(groupSlug),
  );
  const store = useStore();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `network_${networkId}:group_category_${group?.group_category_id}:group_profile`;
  const { data: app } = useLoadApp(
    { externalAppId },
    { enabled: !!group?.group_category_id },
  );

  const { mutate: onUpdateGroup } = useMutation(updateGroup, {
    onSuccess: (data, variables) => {
      toastr.success(
        I18n.t("js.groups.update.success", {
          group: get(variables, ["body", "group", "name"]),
        }),
      );
      location.replace(
        `/groups/${data.groupSlug}/activitystreams/${data.groupSlug}`,
      );
    },
    onError: () => {
      toastr.error(
        I18n.t("js.groups.update.failure", {
          group: group.name,
        }),
      );
    },
  });

  const onSubmit = async (
    values,
    dispatch,
    { externalAppId, externalItemId },
  ) => {
    const { profile, ...group } = values;

    await persistItem(app, store.dispatch, {
      externalAppId,
      externalItemId,
      values: profile,
    });

    onUpdateGroup({ groupSlug, body: { group } });
  };

  return (
    <>
      <TixxtPageTitle />
      {!isEmpty(group) ? (
        <>
          {group.can.archive ? <EditGroupActions {...group} /> : null}
          {!group.archived ? (
            <GroupForm
              {...group}
              onSubmit={onSubmit}
              formName={`group-${group.id}`}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
