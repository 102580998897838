import React from "react";

type LabelArgs = {
  label: string;
  required?: boolean;
};
export default function Label({ label, required = false }: LabelArgs) {
  return (
    <label className="control-label">
      {label} {required && <abbr title={I18n.t("required")}>*</abbr>}
    </label>
  );
}
