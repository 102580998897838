import type { ComponentType } from "react";
import React from "react";
import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import classNames from "classnames";
import FileSelector from "components/files/composer/FileSelector.js";
import { FileData } from "components/../@types/files";

type FileInputArgs = {
  connectDropTarget?: (element: React.ReactElement<any, any>) => void;
  onSelectFile: (e: any) => void;
  multiple?: boolean;
  acceptedType: string;
  empty: boolean;
  isImage: boolean;
  isOver?: boolean;
  selectExisting?: boolean;
  onSelectExistingFile: (selectedFiles: FileData[]) => void;
  onDropFile: ({ files }: { files: any }) => void;
  storageDirectory: string;
};

function FileInput({
  connectDropTarget,
  onSelectFile,
  multiple,
  acceptedType,
  empty,
  isImage,
  isOver,
  selectExisting,
  onSelectExistingFile,
}: FileInputArgs) {
  return multiple || (!multiple && empty) ? (
    connectDropTarget?.(
      <div className="filesInput flex-1">
        <div
          className={classNames(
            "flex flex-col gap-2 h-full items-center justify-center min-h-[100px] uploader-dropzone",
            { isOver },
          )}
        >
          <p className={"font-medium"}>
            {I18n.t(
              `js.files.uploader.drag_and_drop_${
                isImage ? (multiple ? "images" : "image") : "files"
              }`,
            )}
          </p>
          <label className="fileInputButton btn btn-light btn-sm fileFocusButton">
            <i className="fa-regular fa-upload" />{" "}
            {I18n.t(
              `js.files.uploader.select_${isImage ? "image" : "file"}${
                multiple ? "s" : ""
              }`,
            )}
            <input
              type="file"
              onChange={onSelectFile}
              multiple={multiple}
              accept={acceptedType}
              style={{ width: "1px", opacity: 0 }}
              disabled={!multiple && !empty}
            />
          </label>
          {selectExisting ? (
            <FileSelector
              currentRootFolder={
                Tixxt.currentContext
                  ? Tixxt.currentContext.rootFolder().toJSON()
                  : Tixxt.currentNetwork.rootFolder().toJSON()
              }
              onSelectFiles={onSelectExistingFile}
            />
          ) : null}
        </div>
      </div>,
    )
  ) : (
    <div className="filesInput">
      <div className="filesDropTarget">
        <p>
          {I18n.t(
            `js.files.uploader.remove_${isImage ? "image" : "file"}_hint`,
          )}
        </p>
      </div>
    </div>
  );
}

export default DropTarget(
  [NativeTypes.FILE],
  {
    drop(props: { onDropFile(arg: unknown) }, monitor) {
      props.onDropFile(monitor.getItem());
      return props;
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(FileInput as ComponentType<FileInputArgs>);
