export const IS_CHAT_QUERY = "__IS_CHAT_QUERY__";
export const IS_NETWORK_QUERY = "__IS_NETWORK_QUERY__";

/**
 * All actions, for documentation:
 * - chat/CHAT/SCROLL_START_REACHED
 * - chat/CHAT/SCROLL_END_REACHED
 * - chat/CHAT/MARK_AS_READ/START_TIMER
 */

export const createChatQueryAction = (event) => {
  const type = `chat/${event.toUpperCase()}`;
  const actionCreator = (data) => ({
    ...data,
    type,
    [IS_CHAT_QUERY]: true,
  });

  actionCreator.type = type;
  actionCreator.replyType = `${type}/REPLY`;

  return actionCreator;
};
export const createNetworkQueryAction = (event) => {
  const basicActionCreator = createChatQueryAction(event);
  return (data) => ({
    [IS_NETWORK_QUERY]: true,
    ...basicActionCreator(data),
  });
};

// Loads initial message history and other chat details, like permissions
export const loadChatDetails = createChatQueryAction("chat_details");
export const loadMoreMessages = createChatQueryAction("more_messages");
export const markAsRead = createChatQueryAction("mark_as_read");
export const muteChat = createChatQueryAction("mute_chat");
export const muteAppChat = createChatQueryAction("app_mute_chat");
export const unmuteChat = createChatQueryAction("unmute_chat");
export const unmuteAppChat = createChatQueryAction("app_unmute_chat");
export const createChat = createNetworkQueryAction("create_chat");
export const setChatStatus = createNetworkQueryAction("set_status");
