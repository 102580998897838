import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQueryParams } from "../../../helpers/useQueryParams";
import AssignedMember from "./details/AssignedMember";
import { WallViewFilterPortal } from "../../layout/portals";

function HeaderButtons({ url, type }) {
  const queryParams = useQueryParams();
  return (
    <>
      {type == "archive" ? (
        <div className="btn-toolbar">
          <a className="btn btn-light " href={`${url}/items`}>
            <i className="fa fa-arrow-left text-primary" />
          </a>
        </div>
      ) : (
        <WallViewFilterPortal>
          <a className="" href={`${url}/items`}>
            <div className="flex gap-1 items-center">
              <FontAwesomeIcon icon={regular("filter-circle-xmark")} />

              {"tag" in queryParams && (
                <span className="tag">
                  <a className="hover:no-underline">{queryParams.tag}</a>{" "}
                </span>
              )}

              {"assignee_id" in queryParams && (
                <AssignedMember
                  key={queryParams.assignee_id}
                  displayValue={queryParams.assignee_name}
                  imageId={queryParams.image_id}
                />
              )}
            </div>
          </a>
        </WallViewFilterPortal>
      )}
    </>
  );
}

HeaderButtons.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default HeaderButtons;
