import React, { useState } from "react";
import Search from "./search";
import FolderSelectModal from "./FolderSelectModal";
import {
  useMoveFilesAndFolders,
  useRemoveFilesAndFolders,
} from "components/files/api";

type FolderActionArgs = {
  folderId: string;
  searchEnabled: boolean;
  searchProps: {
    contextId: string;
    contextType: "group" | "network";
    values: object;
    showLoading: boolean;
  };
  name: string;
  namespace: string;
  onRemoveItems: (ids: string[]) => void;
  onMoveItems: (id: string) => void;
  can: {
    add_files: boolean;
    update: boolean;
    destroy: boolean;
    create_subfolders: boolean;
  };
  type: string;
};

function FolderActions({
  folderId,
  searchEnabled,
  searchProps,
  can,
  name,
  namespace,
  onRemoveItems,
  onMoveItems,
  type,
}: FolderActionArgs) {
  const [showModal, setShowModal] = useState(false);
  const contextPath =
    searchProps.contextType == "group"
      ? `/groups/${searchProps.contextId}`
      : "";

  const { mutate: remove } = useRemoveFilesAndFolders({
    onSuccess: () => {
      onRemoveItems([folderId]);
    },
  });

  const { mutate: move } = useMoveFilesAndFolders({
    onSuccess: (data, { targetFolderId }) => {
      onMoveItems(targetFolderId);
    },
  });

  function removeFolder(e) {
    e.preventDefault();

    if (confirm(I18n.t("js.files.folder.delete_confirmation", { name }))) {
      remove([
        {
          type: "folders",
          body: {
            folder_ids: [folderId],
          },
        },
      ]);
    }
  }

  function moveFolder(targetFolder) {
    move({
      targetFolderId: targetFolder.id,
      requests: [
        {
          type: "folders",
          body: {
            folder_ids: [folderId],
            target_folder_id: targetFolder.id,
          },
        },
      ],
    });
  }

  return (
    <>
      {showModal && (
        <FolderSelectModal
          modalTitle={I18n.t("js.files.move.modal.title")}
          currentRootFolder={{ id: folderId, namespace }}
          selectedItemIds={[folderId]}
          onSelectFolder={moveFolder}
          closeModal={() => setShowModal(false)}
        />
      )}
      <div className="btn-toolbar divide-gray-300 divide-x justify-end items-stretch mb-4">
        {searchEnabled && <Search {...searchProps} />}
        {(can?.add_files || type !== "activitystream_folder") && (
          <div className="pl-2 first:pl-0">
            <div className="btn-group">
              {can?.add_files && (
                <a
                  href={`${contextPath}/files/new?folder_id=${folderId}`}
                  className="btn btn-primary whitespace-nowrap"
                >
                  <i className="fa-regular fa-circle-plus mr-2" />
                  {I18n.t("js.files.add_files")}
                </a>
              )}
              {type !== "activitystream_folder" && (
                <>
                  <a
                    className="btn btn-light dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-solid fa-ellipsis" />
                  </a>
                  <ul className="context-menu dropdown-menu left">
                    {can?.create_subfolders && (
                      <li>
                        <a
                          href={`${contextPath}/files/folders/new?folder_id=${folderId}`}
                        >
                          <i className="fa-regular fa-circle-plus mr-2" />
                          {I18n.t("js.files.new_folder")}
                        </a>
                      </li>
                    )}
                    {can?.destroy && (
                      <li>
                        <a
                          href="#move"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-regular fa-arrows-up-down-left-right mr-2" />
                          {I18n.t("js.files.folder.headline_tools.move_folder")}
                        </a>
                      </li>
                    )}
                    {can?.update && (
                      <>
                        <li className="divider" />
                        <li>
                          <a
                            href={`${contextPath}/files/folders/${folderId}/edit`}
                          >
                            <i className="fa-regular fa-pen-to-square mr-2" />
                            {I18n.t(
                              "js.files.folder.headline_tools.edit_folder",
                            )}
                          </a>
                        </li>
                      </>
                    )}
                    {can?.destroy && (
                      <li>
                        <a
                          className="text-danger"
                          href="#destroy"
                          onClick={removeFolder}
                        >
                          <i className="fa-regular fa-trash-can mr-2" />
                          {I18n.t(
                            "js.files.folder.headline_tools.delete_folder",
                          )}
                        </a>
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FolderActions;
