import React, { useCallback } from "react";
import {
  isEmpty,
  map,
  each,
  compact,
  get,
  last,
  zip,
  isMatch,
  groupBy,
  sum,
  find,
  has,
  filter,
} from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Control, ControllerRenderProps, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";

import withDragDropContext from "components/shared/withDragDropContext";
import ImagePreview from "components/shared/fields/fileUploadField/ImagePreview";
import FileInput from "components/shared/fields/fileUploadField/FileInput";
import UploadedFile from "components/shared/fields/fileUploadField/UploadedFile";
import UploadBar from "components/shared/fields/fileUploadField/UploadBar";
import { FileData } from "../../../../@types/files";
import { fetchApi } from "helpers/reactQueryApi";

type FileUploadArgs = {
  multiple: boolean;
  storageDirectory: string;
  isImage: boolean;
  selectExisting: boolean;
  field: ControllerRenderProps<any>;
  control: Control<any>;
  accept: string;
};

type UploadFileArgs = {
  fileData: { clientId: string; file: File; storageDirectory: string };
  setProgress: (data: FileData) => void;
};
async function uploadFile({ fileData, setProgress }: UploadFileArgs) {
  const data = new FormData();
  data.append("file", fileData.file);
  data.append("name", fileData.file.name);
  data.append("type", fileData.file.type);
  data.append("external_id", fileData.clientId);

  const response = await axios.post(
    `/api/storage/${fileData.storageDirectory}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: ({ loaded, total }) => {
        if (total) {
          const progress = (loaded * 100) / total;
          setProgress({
            ...fileData.file,
            name: fileData.file.name,
            type: fileData.file.type,
            size: fileData.file.size,
            clientId: fileData.clientId,
            progress,
          });
        }
      },
    },
  );

  return response.data;
}

// Use this with react-hook-form
function FileUpload({
  multiple,
  storageDirectory = "files",
  isImage,
  selectExisting,
  accept,
  field,
  control,
}: FileUploadArgs) {
  const selectedFiles = useWatch({ control, name: field.name });

  const { mutate: getFileToken } = useMutation<
    { token: string },
    Error,
    { fileId: string }
  >(
    async ({ fileId }) =>
      fetchApi(`/api/storage/files/${fileId}/token`, {
        method: "GET",
      }),
    {
      onSuccess: (data, params) => {
        setToken(data.token, params.fileId);
      },
    },
  );

  const setProgress = useCallback(
    (data: FileData) => {
      field.onChange((selectedFiles: FileData[]) => {
        const newValue = map(selectedFiles, (f) =>
          isMatch(f, { clientId: data.clientId }) ? { ...f, ...data } : f,
        );

        if (!find(selectedFiles, { clientId: data.clientId })) {
          newValue.push(data);
        }

        return newValue;
      });
    },
    [field],
  );

  const setToken = useCallback(
    (token: string, fileId: string) => {
      field.onChange((selectedFiles: FileData[]) => {
        const newValue = map(selectedFiles, (f) =>
          isMatch(f, { id: fileId }) ? { ...f, token } : f,
        );

        return newValue;
      });
    },
    [field],
  );

  const { mutate: onUploadFile } = useMutation(
    (fileData: { clientId: string; file: File; storageDirectory: string }) =>
      uploadFile({ fileData, setProgress }),
    {
      onSuccess: (data, variables) => {
        setProgress({
          ...data,
          id: data.id,
          name: variables.file.name,
          size: variables.file.size,
          type: variables.file.type,
          clientId: variables.clientId,
          state: "volatile",
          progress: 100,
        });
      },
      onError: (error: { response: { status: string } }, data) => {
        setProgress({
          ...data.file,
          clientId: data.clientId,
          progress: 0,
          state: "failed",
          status: error.response.status,
        });
      },
    },
  );

  const onRemoveFile = useCallback(
    (criteria: { [key: string]: string }) => {
      window?.bridge?.confirm(I18n.t("js.files.file.delete_confirm"), () => {
        field.onChange((selectedFiles: FileData[]) =>
          compact(map(selectedFiles, (f) => (isMatch(f, criteria) ? null : f))),
        );
      });
    },
    [field],
  );

  // Queues given files for uploading.
  // Discards all but the first file if it is non-multiple
  // and displays warning to the user.
  function handleUploadFiles(rawFiles: File[]) {
    const filesData = map(rawFiles, (file) => ({
      name: file.name,
      type: file.type,
      extension: last(file.name.split(".")),
      clientId: uuidv4(),
      progress: 0,
      state: "uploading",
    }));

    field.onChange([...selectedFiles, ...filesData]);

    each(zip(rawFiles, filesData), (zippedPair) => {
      const [file, fileData] = zippedPair;
      if (file && fileData) {
        onUploadFile({
          clientId: fileData.clientId,
          file,
          storageDirectory,
        });
      }
    });
  }

  const onSelectExistingFile = useCallback(
    (selectedExistingFiles: FileData[]) => {
      field.onChange((selectedFiles: FileData[]) => {
        const newFiles = compact(
          map(selectedExistingFiles, (file) => {
            file.id && getFileToken({ fileId: file.id });
            return find(selectedFiles, { id: file.id }) ? null : file;
          }),
        );

        return [...selectedFiles, ...newFiles];
      });
    },
    [field],
  );

  const filesByState = {
    published: [] as FileData[],
    volatile: [] as FileData[],
    uploading: [] as FileData[],
    failed: [] as FileData[],
    ...groupBy(selectedFiles, "state"),
  };

  // Files without state that came with the defaultValue through e.g. edit something
  const filesWithoutState = map(
    filter(selectedFiles, (file) => !has(file, "state")),
    (file) => ({ ...file, state: "published" }),
  );

  const availableFiles = [
    ...filesWithoutState,
    ...filesByState.published,
    ...filesByState.volatile,
  ];
  const uploadingFiles = [...filesByState.volatile, ...filesByState.uploading];
  const nonRemovedFiles = [
    ...filesWithoutState,
    ...filesByState.published,
    ...filesByState.volatile,
    ...filesByState.failed,
    ...filesByState.uploading,
  ];

  return (
    <>
      <div className="fileUploadField border-box p-3">
        <div className="flex items-stretch gap-2 mt-2">
          {isImage && !multiple ? (
            <ImagePreview
              imageId={get(availableFiles, [0, "id"])}
              storageDirectory={storageDirectory}
            />
          ) : null}
          <FileInput
            isImage={isImage}
            acceptedType={
              accept ||
              (storageDirectory === "images" || storageDirectory === "assets"
                ? "image/*"
                : "*")
            }
            storageDirectory={storageDirectory}
            multiple={multiple}
            empty={isEmpty(availableFiles)}
            onSelectFile={(e) => handleUploadFiles(e.target.files)}
            selectExisting={!isImage && selectExisting}
            onSelectExistingFile={onSelectExistingFile}
            onDropFile={({ files }) => handleUploadFiles(files)}
            {...field}
          />
        </div>
        <div className="upload-states-view flex flex-col gap-px py-px -m-3 mt-4">
          {uploadingFiles.length > 1 && (
            <div className="upload-status">
              <div className="upload-progress">
                <UploadBar
                  progress={
                    sum(map(uploadingFiles, "progress")) / uploadingFiles.length
                  }
                  state="uploading"
                />
              </div>
              <div className="upload-controls">
                <div>
                  <span className="text-sm">
                    {I18n.t("js.files.uploader.total_progress", {
                      completed: filesByState.volatile.length,
                      total: uploadingFiles.length,
                    })}
                  </span>
                </div>
              </div>
            </div>
          )}
          {map(nonRemovedFiles, (file, key) => (
            <UploadedFile
              key={`${file.external_id || file.id}-${key}`}
              file={file}
              remove={onRemoveFile}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default withDragDropContext(FileUpload);
