import React from "react";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import { useParams } from "helpers/tixxt-router";
import { useChannel } from "components/channels/api";

export default function ChannelAdministrationUserbar() {
  const { channelSlug } = useParams();
  const { data: channel, isLoading } = useChannel(channelSlug);
  return (
    <>
      {!window.isApp && <TixxtModuleSwitcher activeModule={"channels"} />}
      <ContextNavigation>
        <UserbarSection
          title={isLoading ? I18n.t("js.loading") : channel?.title}
        >
          <UserbarLink to={`/channels/${channelSlug}/edit`}>
            {I18n.t("js.administration.userbar.channels.settings.title")}
          </UserbarLink>
          <UserbarLink to={`/channels/${channelSlug}/layout/edit`}>
            {I18n.t("js.administration.userbar.channels.layout.title")}
          </UserbarLink>
        </UserbarSection>
      </ContextNavigation>
    </>
  );
}
