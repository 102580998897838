import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFileLines,
  faFileCode,
  faFileCsv,
  faFilePdf,
  faFileZipper,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
} from "@fortawesome/pro-light-svg-icons";

function chooseFileIcon(extension: string) {
  const audioExtensions = [
    "mp3",
    "aac",
    "flac",
    "ogg",
    "alac",
    "wav",
    "aiff",
    "dsd",
    "pcm",
    "aif",
    "mid",
    "midi",
    "map3",
    "wma",
  ];
  const videoExtensions = [
    "webm",
    "mkv",
    "flv",
    "vob",
    "ogv",
    "drc",
    "gifv",
    "avi",
    "mov",
    "mts",
    "wmv",
    "yuv",
    "amv",
    "mp4",
    "m4p",
    "m4v",
    "mpg",
    "mpeg",
    "m2v",
    "svi",
    "3gp",
    "flv",
    "f4v",
    "f4p",
    "f4a",
    "f4b",
    "3g2",
    "mk4v",
  ];
  const pictureExtensions = [
    "gif",
    "jpeg",
    "jpg",
    "png",
    "tiff",
    "tif",
    "psd",
    "ai",
    "indd",
    "raw",
    "eps",
    "cr2",
    "crw",
    "nef",
    "pef",
    "cdr",
    "xcf",
    "bmp",
  ];
  const textExtensions = ["odt", "rtf", "tex", "txt", "pages"];
  const codeExtensions = [
    "c",
    "cgi",
    "pl",
    "class",
    "cpp",
    "cs",
    "h",
    "java",
    "php",
    "py",
    "sh",
    "swift",
    "vb",
    "rb",
    "js",
    "haml",
    "hamlc",
    "coffee",
    "rs",
    "jsx",
    "tsx",
    "json",
    "yml",
    "html",
    "css",
    "scss",
  ];
  const wordExtensions = ["wpd", "doc", "docx", "docm", "dotx"];
  const powerpointExtensions = [
    "pps",
    "ppt",
    "pptx",
    "potm",
    "potx",
    "ppam",
    "ppsm",
    "ppsx",
    "pptm",
  ];
  const excelExtensions = [
    "xls",
    "xlsm",
    "xlsx",
    "xlam",
    "xlsb",
    "xltm",
    "xltx",
  ];
  const zipExtensions = [
    "7z",
    "arj",
    "deb",
    "pkg",
    "rar",
    "rpm",
    "tag.gz",
    "z",
    "zip",
  ];

  if (audioExtensions.includes(extension)) {
    return faFileAudio; // audio
  } else if (videoExtensions.includes(extension)) {
    return faFileVideo; //video
  } else if (pictureExtensions.includes(extension)) {
    return faFileImage; // images
  } else if (textExtensions.includes(extension)) {
    return faFileLines; // text
  } else if (codeExtensions.includes(extension)) {
    return faFileCode; // code
  } else if (extension === "csv") {
    return faFileCsv; // csv
  } else if (extension === "pdf") {
    return faFilePdf; //pdf
  } else if (wordExtensions.includes(extension)) {
    return faFileWord; //word
  } else if (powerpointExtensions.includes(extension)) {
    return faFilePowerpoint; // powerpoint
  } else if (excelExtensions.includes(extension)) {
    return faFileExcel; // excel
  } else if (zipExtensions.includes(extension)) {
    return faFileZipper; // zip
  } else {
    return faFile; // everything else
  }
}

function FileIcon({
  extension,
  muted = false,
  className,
}: {
  extension: string;
  muted?: boolean;
  className?: string;
}) {
  const icon = chooseFileIcon(extension);

  return (
    <FontAwesomeIcon
      icon={icon}
      className={classNames(
        `file-type file-type-${extension}`,
        className || "text-2xl",
        { muted: muted },
      )}
    />
  );
}

export default FileIcon;
