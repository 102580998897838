import React from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/shared/form/fields/TextInput";
import FormActions from "components/shared/form/fields/FormActions";
import {
  onValidationError,
  resetValidationErrors,
} from "helpers/formValidations";
import { useSaveBlog } from "../api";
import { Blog } from "components/blogs/types";
import RadioButton from "components/shared/form/fields/RadioButton";

type BlogFormData = {
  title: string;
  description: string;
  publish_as: string;
};

export default function BlogForm({ blog }: { blog: Blog }) {
  const { register, handleSubmit } = useForm<BlogFormData>({
    defaultValues: {
      title: blog.title || "",
      description: blog.description || "",
      publish_as: blog.publish_as || "",
    },
  });

  const { mutate: saveBlog } = useSaveBlog(blog, {
    onSuccess: () => {
      window.location.href = `/blogs/${blog.id}`;
      toastr.success(I18n.t("js.saving_successful"));
    },
    onError: (error: { message: string }) => {
      toastr.error(error.message || I18n.t("js.saving_failure"));
    },
  });

  function submit(data: BlogFormData) {
    resetValidationErrors();
    saveBlog({ blog: { id: blog.id, parent_id: blog.parent_id, ...data } });
  }

  return (
    <form
      className="form-horizontal mb-6"
      onSubmit={handleSubmit(submit, onValidationError)}
    >
      <TextInput
        name="title"
        label={I18n.t("js.blog.form.title")}
        register={register}
        required={I18n.t("js.blog.form.title_required")}
      />
      <TextInput
        name="description"
        label={I18n.t("js.blog.form.description")}
        register={register}
        multiline
      />
      <RadioButton
        name="publish_as"
        label={I18n.t("js.blog.form.publish_as.label")}
        register={register}
        defaultValue={blog.publish_as || "blog_title"}
        options={[
          {
            label: I18n.t("js.blog.form.publish_as.blog_title"),
            value: "blog_title",
          },
          { label: I18n.t("js.blog.form.publish_as.author"), value: "author" },
        ]}
        hint={I18n.t("js.blog.form.publish_as.hint")}
      />
      <FormActions
        onCancel={(e) => {
          e.preventDefault();
          window.location.href = `/channels/${blog.parent_id}`;
        }}
      />
    </form>
  );
}
