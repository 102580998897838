import React from "react";
import Select from "react-select";
import { map, isEmpty, includes, get } from "lodash";
import { useQuery } from "react-query";
import { stringify } from "query-string";
import { Control, Controller } from "react-hook-form";
import Label from "components/shared/form/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type MeetingRoom = {
  name: string;
  occupied: boolean;
};

const useReservableMeetingRooms = ({ dateRange, roomId, loadData }) => {
  const query = {
    filter: "reservable",
    ...dateRange,
    room_id: roomId || null,
  };

  const url = `/meeting_rooms?${stringify(query)}`;

  return useQuery<MeetingRoom[]>(url, { enabled: loadData });
};

type MeetingRoomSelectArgs = {
  dateRange: {
    start: string;
    end?: string;
  };
  control: Control<any>;
};

export default function MeetingRoomSelect({
  dateRange,
  control,
}: MeetingRoomSelectArgs) {
  return (
    <div className="control-group remove-input-txt-border">
      <Label
        label={I18n.t("js.calendars.appointment.meeting.meeting_rooms.label")}
      />
      <div className="controls">
        <Controller
          name="meeting"
          control={control}
          render={({ field }) =>
            renderMeetingRoomSelect({
              field,
              dateRange,
              disabled: !dateRange.end,
            })
          }
        />
      </div>
    </div>
  );
}

function renderMeetingRoomSelect({ field, dateRange, disabled }) {
  const preSelectedRoomId = get(field.value, "room_id") || null;

  const loadData = !isEmpty(dateRange.end);

  const {
    isLoading: loading,
    data: meetingRooms,
    error,
  } = useReservableMeetingRooms({
    dateRange,
    roomId: preSelectedRoomId,
    loadData,
  });

  function handleChange(value: { [key: string]: string } | undefined) {
    field.onChange(value ? { room_id: value.id, room_name: value.name } : null);
  }

  const selectOptions = map(meetingRooms, (room) => ({
    value: room,
    label:
      room.name +
      (room.occupied
        ? " " + I18n.t("js.calendars.appointment.meeting.occupied")
        : ""),
    isDisabled: room.occupied,
  }));

  return loading ? (
    <div className="text-danger border-box py-1.5 px-3">
      <FontAwesomeIcon icon={regular("spinner")} spin />{" "}
      {I18n.t("js.calendars.appointment.meeting.meeting_rooms.loading")}
    </div>
  ) : error ? (
    <div className="text-danger border-box py-1.5 px-3">
      {I18n.t("js.generic_error")}
    </div>
  ) : isEmpty(meetingRooms) && !disabled ? (
    <div className="text-danger border-box py-1.5 px-3">
      {I18n.t("js.calendars.appointment.meeting.meeting_rooms.nothing_free")}
    </div>
  ) : (
    <>
      <Select
        value={
          includes(map(meetingRooms, "id"), get(field.value, "room_id"))
            ? { value: field.value.room_id, label: field.value.room_name }
            : null
        }
        options={selectOptions}
        onChange={(selectedOption) => handleChange(selectedOption?.value)}
        placeholder={I18n.t(
          "js.calendars.appointment.meeting.meeting_rooms.select",
        )}
        classNamePrefix="form-select"
        isDisabled={disabled}
        isClearable
        unstyled
      />
      <span className="text-muted text-sm">
        {disabled
          ? I18n.t("js.calendars.appointment.meeting.meeting_rooms.select_hint")
          : I18n.t("js.calendars.appointment.meeting.buffer_time")}
      </span>
    </>
  );
}
