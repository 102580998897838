import React from "react";
import { isEmpty } from "lodash";

interface ImagePreviewTypes {
  imageId?: string;
  storageDirectory?: string;
}

function ImagePreview({
  imageId,
  storageDirectory = "images",
}: ImagePreviewTypes) {
  return (
    <div className={"imageView"}>
      {!isEmpty(imageId) ? (
        <img
          className="max-h-32 max-w-32"
          src={`/api/storage/${storageDirectory}/${imageId}/${
            storageDirectory === "images" ? "get/200x200" : "preview"
          }`}
        />
      ) : (
        <div className="imageIcon w-32 h-32 bg-gray-100 text-gray-400 text-center pt-4">
          <i className="fa-light fa-image text-8xl" />
        </div>
      )}
    </div>
  );
}

export default ImagePreview;
