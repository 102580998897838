import React, { ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import Async from "react-select/async";
import { stringify } from "query-string";
import { map, isEmpty, get, keys, isEqual, filter, forEach } from "lodash";

import { createApiAction } from "helpers/api";
import { showNameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import { FieldElement } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const fetchMembers = createApiAction({
  baseType: "FETCH_MEMBERS",
  endpoint: ({
    groupSlug,
    includeConsumers,
    input,
    excludeGroupMembersOf,
    recipientId,
  }) => {
    const params = isEqual(input, recipientId) ? {} : { q: input };

    if (includeConsumers) Reflect.set(params, "include_consumers", true);
    if (!isEmpty(excludeGroupMembersOf))
      Reflect.set(params, "exclude_group_members_of", excludeGroupMembersOf);

    return [
      groupSlug ? `/groups/${groupSlug}` : null,
      `/members?`,
      stringify(params),
    ].join("");
  },
});

const fetchMembersAndGroups = createApiAction({
  baseType: "FETCH_MEMBERS_AND_GROUPS",
  endpoint: ({ input, recipientId, includeMembershipRoles = false }) =>
    `/directory?${
      isEqual(input, recipientId)
        ? ""
        : stringify({
            q: input,
            include_membership_roles: includeMembershipRoles,
          })
    }`,
});

const optionsKey = (key) => {
  switch (key) {
    case "member":
      return I18n.t("js.member_select.options.key.member");
    case "group":
      return I18n.t("js.member_select.options.key.group");
    default:
      return key;
  }
};

type options = Array<{
  value: string | undefined;
  label: Array<React.ReactNode>;
  type: string;
}>;
const groupOptions = (options: options) => {
  const groupedOptions = {
    member: [] as options,
    group: [] as options,
    other: [] as options,
  };

  forEach(options, (option) => {
    switch (option.type) {
      case "member":
        groupedOptions["member"].push(option);
        break;
      case "membershipRole":
      case "group":
        groupedOptions["group"].push(option);
        break;
      default:
        groupedOptions["other"].push(option);
    }
  });
  return groupedOptions;
};

type Entries = Entry[];
type Entry = {
  type: string;
  name: string;
  membership_roles: MembershipRole[];
};

export type Value = {
  value?: string;
  label?: string | ReactNode[];
  type?: string;
};

type MembershipRole = {
  type: string;
  name: string;
  membership_status_of_current_member?: string;
  id?: string;
  detection_profile_field?: string;
  is_external?: boolean;
};

const mergeEntries = (entries: Entries) => {
  const mergedEntries: Entries[number]["membership_roles"][number][] = [];

  forEach(entries, (entry) => {
    if (entry.type === "group") {
      const name = `${entry.name} (${I18n.t(
        "js.member_select.all_group_members",
      )})`;
      mergedEntries.push({ ...entry, name });

      if (entry.membership_roles && entry.membership_roles.length > 0) {
        forEach(entry.membership_roles, (membershipRole) => {
          const name = entry.name + " - " + membershipRole.name;
          mergedEntries.push({ ...membershipRole, name });
        });
      }
    } else {
      mergedEntries.push(entry);
    }
  });
  return mergedEntries;
};

interface MemberSelectTypes {
  groupSlug?: string;
  includeConsumers?: boolean;
  includeMembershipRoles?: boolean;
  excludeGroupMembersOf?: string;

  fetchMembers?(...args: unknown[]): Promise<{
    payload: Array<{
      displayName: string;
      id: string;
      name: string;
      detection_profile_field: string;
      is_external: boolean;
    }>;
  }>;

  fetchMembersAndGroups?(...args: unknown[]): Promise<unknown>;

  input?: {
    onChange: (selectedEntries: Value[]) => void;
    onBlur?(...args: unknown[]): unknown;
    value?: Value[] | undefined;
  };
  onChange?: (selectedEntries: Value[]) => void;
  multi: boolean;
  membersAndGroups?: boolean;
  className?: string;
  wrapperStyle?: Record<PropertyKey, string>;
  value?: Value[] | undefined;
  recipientId?: string;
  onlyMyGroups?: boolean;
  id?: string;
  field?: FieldElement;
}

function MemberSelect(props: MemberSelectTypes) {
  // Local fetch through redux api middleware for now
  // Can be optimized later i.e. by using redux state to memoize stuff
  function loadOptions(input): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const {
        groupSlug,
        includeConsumers,
        includeMembershipRoles,
        excludeGroupMembersOf,
        recipientId,
        onlyMyGroups,
      } = props;
      props.membersAndGroups
        ? props
            .fetchMembersAndGroups?.({
              input,
              recipientId,
              includeMembershipRoles,
            })
            .then((success) => {
              if (!success) {
                return reject("error");
              }

              const entries = get(
                success,
                "payload.entries",
              ) as unknown as Entries;

              const mergedEntries = mergeEntries(entries);

              const mappedOptions = map(
                onlyMyGroups
                  ? filter(mergedEntries, (e) =>
                      e.membership_status_of_current_member
                        ? isEqual(
                            e.membership_status_of_current_member,
                            "member",
                          )
                        : e,
                    )
                  : mergedEntries,
                (entry: MembershipRole) => {
                  if (recipientId && isEqual(entry.id, recipientId)) {
                    props.input?.onChange?.([
                      {
                        value: entry.id,
                        label: [
                          <FontAwesomeIcon key="icon" icon={regular("user")} />,
                          " ",
                          entry.name,
                        ],
                        type: "member",
                      },
                    ]);
                  }

                  const icon = {
                    member: regular("user"),
                    group: regular("users"),
                    standard: regular("chevron-right"),
                  };

                  return {
                    value: entry.id,
                    label: [
                      <FontAwesomeIcon
                        key="icon"
                        icon={icon[entry.type || "standard"]}
                      />,
                      " ",
                      showNameWithProfileField({
                        name: entry.name,
                        detectionProfileField: entry.detection_profile_field,
                        isExternal: entry.is_external,
                      }),
                    ],
                    type: entry.type,
                  };
                },
              );

              const groupedOptions = groupOptions(
                mappedOptions as unknown as {
                  value: string | undefined;
                  label: React.ReactNode[];
                  type: string;
                }[],
              );

              const options = map(keys(groupedOptions), (key) => ({
                label: optionsKey(key),
                options: groupedOptions[key],
              }));

              resolve(options);
            })
            .catch(reject)
        : props
            .fetchMembers?.({
              groupSlug,
              includeConsumers,
              input,
              excludeGroupMembersOf,
              recipientId,
            })
            .then((success) => {
              if (!success) {
                return reject("error");
              }

              const options = map(success.payload, (m) => {
                if (isEqual(m.id, recipientId)) {
                  props.input?.onChange?.([
                    { value: m.id, label: m.displayName },
                  ]);
                }
                return {
                  value: m.id,
                  label: showNameWithProfileField({
                    name: m.displayName,
                    detectionProfileField: m.detection_profile_field,
                    isExternal: m.is_external,
                  }),
                };
              });

              resolve(options);
            })
            .catch(reject);
    });
  }

  useEffect(() => {
    if (props.recipientId) loadOptions(props.recipientId);
  }, []);

  const {
    input,
    multi,
    wrapperStyle,
    id,
    field,
    membersAndGroups,
    ...selectProps
  } = props;
  const asyncPropsOptions = {
    ...selectProps,
    isMulti: multi,
    loadOptions,
    placeholder: membersAndGroups
      ? I18n.t("js.audience_select.placeholder")
      : I18n.t("js.member_select.placeholder"),
    loadingMessage: () => I18n.t("js.plugins.select2.searching"),
    noOptionsMessage: ({ inputValue }) =>
      isEmpty(inputValue)
        ? I18n.t("js.plugins.select2.enter_more_characters.one")
        : I18n.t("js.plugins.select2.no_match"),
    inputId: id,
  };
  if (input) {
    // for use with redux form
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Should be rewritten
      <Async
        {...asyncPropsOptions}
        {...input}
        onBlur={() => input?.onBlur?.(input.value)}
        openMenuOnFocus
        styles={{
          container: (base) => ({ ...base, ...wrapperStyle, "z-index": 3 }),
        }}
      />
    );
  } else {
    // for use without redux form
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Should be rewritten
      <Async
        {...asyncPropsOptions}
        {...field}
        className="form-select-container"
        classNamePrefix="form-select"
        unstyled
      />
    );
  }
}

export default connect(null, { fetchMembers, fetchMembersAndGroups })(
  MemberSelect,
);
