import ToggleFavoriteButton from "components/shared/favoriteButtons/ToggleFavoriteButton";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export default function ToggleChannelFavoriteButton({
  channelSlug,
  favorited,
}: {
  channelSlug: string;
  favorited: boolean;
}) {
  const [isFavorited, setIsFavorited] = useState(favorited);
  const { mutate, isLoading } = useMutation<{ favorited: boolean }, Error>(
    () =>
      fetchApi(`/channels/${channelSlug}/favorite`, {
        method: favorited ? "DELETE" : "POST",
      }),
    { onSuccess: (data) => setIsFavorited(data.favorited) },
  );

  useEffect(() => {
    setIsFavorited(favorited);
  }, [favorited]);

  return (
    <ToggleFavoriteButton
      title={
        isFavorited
          ? I18n.t("js.page_title.remove_group_from_favorites")
          : I18n.t("js.page_title.add_group_to_favorites")
      }
      toggleFavorited={() => mutate()}
      isLoading={isLoading}
      favorited={isFavorited}
    />
  );
}
