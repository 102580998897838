import React from "react";
import map from "lodash/map";
import { getWidgetName } from "helpers/widgets/widgets";
import { useQuery } from "react-query";

interface WidgetbarSelectionTypes {
  groupId?: string;
}

export default function WidgetbarSelection({
  groupId,
}: WidgetbarSelectionTypes) {
  const { data, isLoading } = useQuery<{
    widgetbars: {
      slug: string;
      widgets: any[];
    }[];
  }>(`${groupId ? `/groups/${groupId}` : ""}/administration/widgetbars.json`);

  const widgetbars = data?.widgetbars;

  return isLoading ? (
    <div>{I18n.t("js.loading")}</div>
  ) : (
    <table className="tixxt-table">
      <thead>
        <tr>
          <th>{I18n.t("js.administration.widgetbars.table.name")}</th>
          <th>{I18n.t("js.administration.widgetbars.table.widget_count")}</th>
        </tr>
      </thead>
      <tbody>
        {map(widgetbars, (widgetbar) => (
          <tr key={widgetbar.slug}>
            <td>
              <a
                href={`${
                  groupId ? `/groups/${groupId}` : ""
                }/administration/widgetbars/${widgetbar.slug}/edit`}
              >
                {getWidgetName(widgetbar.slug)}
              </a>
            </td>
            <td>{widgetbar.widgets.length}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
