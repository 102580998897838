import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { ChangeEvent, useState } from "react";
import DropDown, {
  DropDownItem,
  DropDownSubMenu,
} from "components/shared/DropDown";
import { includes, isEmpty, map } from "lodash";
import { MembershipRole } from "../../@types";

type MembershipRoleItemArgs = {
  role: MembershipRole;
  membershipRoleIds: string[];
  changeMembershipRole: (e: ChangeEvent<HTMLInputElement>) => void;
};

function MembershipRoleItem({
  role,
  membershipRoleIds,
  changeMembershipRole,
}: MembershipRoleItemArgs) {
  const [checked, setChecked] = useState(includes(membershipRoleIds, role.id));

  return (
    <DropDownItem key={role.id} className="dropdown-item">
      <label className="checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(!checked);
            changeMembershipRole(e);
          }}
          name="role_id"
          value={role.id}
        />
        <span
          className="badge text-muted"
          style={{
            backgroundColor: role?.color || "var(--gray-500)",
            color: role?.text_color || "white",
          }}
        >
          {role.name}
        </span>
      </label>
    </DropDownItem>
  );
}

type MemberManagerDropdownArgs = {
  can: {
    edit: boolean;
    edit_groups: boolean;
    destroy: boolean;
  };
  administrationPath: string;
  membershipId: string;
  isExternal: boolean;
  isLocked: boolean;
  toggleExternalMember: () => void;
  toggleMemberLock: () => void;
  changeMembershipRole: (e: ChangeEvent<HTMLInputElement>) => void;
  roles: MembershipRole[];
  membershipRoles: MembershipRole[];
};

export default function MemberManagerDropdown({
  can,
  administrationPath,
  membershipId,
  isExternal,
  isLocked,
  toggleExternalMember,
  toggleMemberLock,
  roles,
  membershipRoles,
  changeMembershipRole,
}: MemberManagerDropdownArgs) {
  return (
    <>
      <a
        className="btn btn-sm  btn-light text-muted dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        <FontAwesomeIcon icon={solid("ellipsis")} />
      </a>
      <DropDown>
        {can.edit && (
          <DropDownItem
            url={`${administrationPath}/edit`}
            icon={regular("pencil")}
            text={I18n.t("js.administration.members.edit_user_and_profile")}
          />
        )}
        {can.edit_groups && (
          <DropDownItem
            url={`${administrationPath}/group-memberships`}
            icon={regular("users")}
            text={I18n.t("js.administration.members.edit_groups_memberships")}
          />
        )}
        {Preload.current_network.config.membership_roles_enabled &&
          !isEmpty(roles) && (
            <DropDownSubMenu
              text={I18n.t("js.administration.membership_roles.assign.title")}
              side={window.isApp ? "left" : "right"}
              icon={regular("tags")}
              dividerTop
            >
              {map(roles, (role) => (
                <MembershipRoleItem
                  role={role}
                  membershipRoleIds={map(membershipRoles, "id")}
                  changeMembershipRole={changeMembershipRole}
                />
              ))}
            </DropDownSubMenu>
          )}
        {Preload.current_member?.minister && (
          <DropDownItem
            url={`/administration/ministry/memberships/${membershipId}`}
            icon={regular("id-card")}
            text={I18n.t("js.administration.members.ministry_link")}
            dividerTop
          />
        )}
        {can.edit && (
          <>
            {Preload.current_network.config.guest_users.allowed && (
              <DropDownItem
                onSelect={(e) => {
                  e.preventDefault();
                  toggleExternalMember();
                }}
                icon={regular("id-card")}
                text={
                  isExternal
                    ? I18n.t(
                        "js.administration.member_manager.external_to_normal",
                      )
                    : I18n.t(
                        "js.administration.member_manager.normal_to_external",
                      )
                }
                dividerTop
              />
            )}
            {Preload.current_network.config.two_factor !== "disabled" && (
              <DropDownItem
                url={`/administration/members/${membershipId}/authentication/factors`}
                method="delete"
                confirm={I18n.t(
                  "js.administration.authentication.2fa.delete_confirmation",
                )}
                icon={regular("key")}
                text={I18n.t(
                  "js.administration.authentication.2fa.reset_two_factor_configuration",
                )}
                dividerTop
              />
            )}
            <DropDownItem
              onSelect={(e) => {
                e.preventDefault();
                toggleMemberLock();
              }}
              icon={isLocked ? regular("unlock") : regular("lock")}
              text={
                isLocked
                  ? I18n.t("js.administration.member_manager.unlock_user")
                  : I18n.t("js.administration.member_manager.lock_user")
              }
              dividerTop
            />
          </>
        )}
        {can.destroy && (
          <>
            <DropDownItem
              linkStyle="!text-danger"
              url={administrationPath}
              method="delete"
              confirm={I18n.t("js.administration.members.confirm_delete")}
              icon={regular("trash")}
              text={I18n.t("js.administration.members.delete_user")}
              dividerTop
            />
          </>
        )}
      </DropDown>
    </>
  );
}
