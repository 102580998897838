import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { map } from "lodash";

export const useFetchFolder = (id, namespace = "folders") => {
  return useQuery(`/api/storage/${namespace}/${id}?limit=1000`);
};

type useRemoveFilesAndFolders = {
  type: "files" | "folders";
  body: { [key: string]: string[] };
}[];

export function useRemoveFilesAndFolders(
  options?: UseMutationOptions<unknown, Error, useRemoveFilesAndFolders>,
) {
  return useMutation<unknown, Error, useRemoveFilesAndFolders>(
    (requests) =>
      Promise.all(
        map(requests, ({ type, body }) =>
          fetchApi(`/api/storage/${type}`, { method: "DELETE", body }),
        ),
      ),
    options,
  );
}

type useMoveFilesAndFoldersArgs = {
  targetFolderId: string;
  requests: {
    type: "files" | "folders";
    body: {
      [key: string]: string | string[];
    };
  }[];
};

export function useMoveFilesAndFolders(
  options?: UseMutationOptions<unknown, Error, useMoveFilesAndFoldersArgs>,
) {
  return useMutation<unknown, Error, useMoveFilesAndFoldersArgs>(
    ({ requests }) =>
      Promise.all(
        map(requests, ({ type, body }) =>
          fetchApi(`/api/storage/${type}/move`, { method: "POST", body }),
        ),
      ),
    options,
  );
}
