import React, { useEffect, useRef } from "react";
import Datepicker from "helpers/Datepicker";
import moment from "moment";
import { noop } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import TimePicker from "components/shared/form/fields/TimePicker";
import Label from "components/shared/form/Label";
import { ChangeHandler } from "react-hook-form";

type DateTimePickerArgs = {
  date: string;
  value?: string;
  showTimePicker?: boolean;
  onChange: (value: string) => void | ChangeHandler;
  label?: string;
  showTimezone?: boolean;
  timeZone?: string;
  interval?: moment.Duration;
  required?: boolean;
};

export default function DateTimePicker({
  date,
  showTimePicker = false,
  onChange,
  label,
  showTimezone = false,
  timeZone,
  interval,
  required = false,
}: DateTimePickerArgs) {
  const pickerRef = useRef<HTMLInputElement>(null);

  function isDateSelectable(pickerDate: string) {
    const momentDate = moment(pickerDate);
    const startDate = moment(date).clone();
    if (momentDate.isBefore(startDate, "day")) {
      return false;
    }
    const checkDate = startDate.clone().startOf("day");
    while (checkDate.isSameOrBefore(momentDate)) {
      if (checkDate.isSame(momentDate, "day")) {
        return true;
      }
      checkDate.add(interval);
    }
    return false;
  }

  useEffect(() => {
    const options = interval
      ? {
          beforeShowDay: (pickerDate: string) => ({
            enabled: isDateSelectable(pickerDate),
          }),
        }
      : {};

    const picker = new Datepicker(pickerRef.current, options);

    pickerRef.current?.addEventListener("changeDate", (e: CustomEventInit) => {
      onChange(
        moment(e.detail.date)
          .hours(moment(date).hours())
          .minutes(moment(date).minutes())
          .format(),
      );
    });

    return () => {
      picker.destroy();
    };
  }, [interval]);

  return (
    <div className="control-group items-center">
      {label && <Label label={label} required={required} />}
      <div className="controls flex gap-2 items-start">
        <div className="input-group w-36">
          <input
            type="text"
            ref={pickerRef}
            placeholder={I18n.t("js.calendars.date_range.date_placeholder")}
            value={moment(date).format(
              I18n.t("js.plugins.Locale.Formats.valid_date"),
            )}
            onChange={noop}
          />
          <button
            className="btn btn-light btn-sm"
            onClick={(e) => {
              e.preventDefault();
              pickerRef?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon={regular("calendar")} />
          </button>
        </div>
        {showTimePicker && (
          <div className="grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-[auto_1fr] gap-2 items-center">
            <TimePicker onChange={onChange} value={date} />
            <span className="text-sm w-40">
              {showTimezone &&
                timeZone &&
                `UTC ${moment.tz(date, timeZone).format("Z")} (${moment
                  .tz(date, timeZone)
                  .format("z")})`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
