import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";
import React from "react";
import PermissionPicker from "../components/shared/PermissionPicker";
import { Wrapper as QueryClientProvider } from "../helpers/queryClient";

// Connects to data-controller="permission-picker"
// Intended be used on a text input containing JSON
export default class extends Controller {
  connect() {
    this.originalType = this.element.type;
    this.element.type = "hidden";

    const container = document.createElement("div");
    this.element.insertAdjacentElement("afterend", container);
    this.root = createRoot(container);
    const initialValue = JSON.parse(this.element.value || "[]");
    this.handleChange(initialValue);
  }

  handleChange = (newValue) => {
    this.element.value = JSON.stringify(newValue);

    this.root.render(
      <QueryClientProvider>
        <PermissionPicker value={newValue} onChange={this.handleChange} />
      </QueryClientProvider>,
    );
  };

  disconnect() {
    if (this.root) {
      this.root.unmount();
      this.element.type = this.originalType;
    }
  }
}
