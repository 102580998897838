import React from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import ToggleChannelFavoriteButton from "components/shared/favoriteButtons/ToggleChannelFavoriteButton";
import { useChannel } from "components/channels/api";
import { useChannelLayout } from "components/channels/layout/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import { ChannelLayout } from "./ChannelLayout";

export default function ChannelPage() {
  const { slug } = useParams();
  const { data: channel, isLoading } = useChannel(slug);
  const { data: layoutData, isLoading: layoutLoading } = useChannelLayout(slug);

  const dropdownItems: React.JSX.Element[] = [];
  if (channel?.i.can.update)
    dropdownItems.push(
      <DropDownItem
        text={I18n.t("js.channels.title.dropdown.edit_channel")}
        url={`/channels/${channel.id}/edit`}
      />,
    );

  if (channel?.i.can.update_blog)
    dropdownItems.push(
      <DropDownItem
        text={I18n.t("js.channels.title.dropdown.edit_blog")}
        url={`/blogs/${channel.blog_id}/edit`}
      />,
    );

  return (
    <>
      <PageTitle
        title={isLoading ? I18n.t("js.loading") : channel?.title || ""}
      >
        {channel && (
          <div className={"flex place-items-center"}>
            <ToggleChannelFavoriteButton
              channelSlug={channel.slug}
              favorited={channel.i.favorited}
            />
            {dropdownItems.length > 0 && (
              <>
                <button
                  className="btn btn-sm dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <FontAwesomeIcon icon={thin("gear")} />
                </button>
                <DropDown alignment="start">{dropdownItems}</DropDown>
              </>
            )}
          </div>
        )}
      </PageTitle>
      <ChannelLayout layoutData={layoutData} isLoading={layoutLoading} />
    </>
  );
}
