import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

// Connects to data-controller="administration--invites--send-batch-actions"
export default class extends Controller {
  connect() {}

  refreshInvites() {
    const invite_ids = this.#getSelectedInviteIds();
    const model = this.#findInvite(invite_ids[0]);

    Tixxt.Modal.instance().showBodied(
      I18n.t("js.invites.index.details-modal.title"),
      new Tixxt.Invites.BatchRenewInviteView({ model, invite_ids }),
    );
  }

  revokeInvites() {
    $.ajax({
      url: "/batch_invites/revoke",
      method: "post",
      data: {
        invite_ids: this.#getSelectedInviteIds(),
      },
    })
      .done(this.#submitSuccess)
      .fail(this.#submitFailure);
  }

  #getSelectedInviteIds() {
    const selectedCheckboxes = this.element.querySelectorAll(
      'input[name="invite_select"]:checked',
    );
    return Array.from(selectedCheckboxes).map(
      (checkbox) => checkbox.id.split("-")[1],
    );
  }

  #findInvite(id) {
    const model = new Tixxt.Invites.Invite({ id: id });
    model.fetch();
    return model;
  }

  #submitSuccess() {
    toastr.success(I18n.t("js.invites.batch_revoke.success_flash"));
    Turbo.visit(location.pathname, {
      frame: "external-invites-view",
      action: "replace",
    });
  }

  #submitFailure() {
    toastr.error(I18n.t("js.invites.batch_revoke.not_revokable_flash"));
  }
}
