import React, { useMemo } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";

import Label from "components/shared/form/Label";
import Checkbox from "components/shared/form/fields/Checkbox";
import classNames from "classnames";
import DirectoryPicker from "components/shared/DirectoryPicker";
import { networkMembershipRoles } from "components/shared/PermissionPicker";

export function ParticipantsPicker(props) {
  const participantsQuery = useMemo(
    () => ({
      include_consumers: Preload.current_network.config.consumers_enabled,
      include_membership_roles: true,
    }),
    [Preload.current_network.config.consumers_enabled],
  );

  return (
    <DirectoryPicker
      {...props}
      query={participantsQuery}
      multi
      extraOptions={networkMembershipRoles}
    />
  );
}

export default function ParticipantsField({
  control,
  register,
}: {
  control: Control<any>;
  register: UseFormRegister<any>;
}) {
  return (
    <div className={"control-group remove-input-txt-border"}>
      <Label label={I18n.t("js.calendars.appointment.invites.label")} />
      <div
        className={classNames("controls", {
          "border-box p-3": Preload.current_network.config.consumers_enabled,
        })}
      >
        <Controller
          name="participants"
          control={control}
          render={({ field }) => <ParticipantsPicker {...field} />}
        />
        <span className="text-muted text-sm">
          {I18n.t("js.calendars.appointment.invites.disclaimer")}
        </span>
        {Preload.current_network.config.consumers_enabled && (
          <div className="controls mt-2">
            <Checkbox
              name="invite_consumers"
              register={register}
              label={I18n.t(
                "js.calendars.appointment.invites.invite_consumers",
              )}
            />
            <span className="text-muted text-sm">
              {I18n.t("js.calendars.appointment.invites.tooltip")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
