import { useMutation, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export type LayoutItem = {
  id: string;
  type: "full" | "half-1-2" | "half-2-1" | "third";
};

type LayoutResponse = {
  rows: LayoutItem[];
};

export function useChannelLayout(channelId: string | undefined) {
  return useQuery<LayoutResponse>(`/channels/${channelId}/layout`, {
    enabled: !!channelId,
  });
}

export function useUpdateChannelLayout(channelId: string) {
  return useMutation<LayoutResponse, Error, LayoutItem[]>((newItems) =>
    fetchApi(`/channels/${channelId}/layout`, {
      method: "PUT",
      body: { rows: newItems },
    }),
  );
}
