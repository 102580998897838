import React from "react";
import { useStore, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useParams } from "helpers/tixxt-router";

import { selectNetworkId } from "selectors/environment";
import persistItem from "helpers/items/persistItem";

import MembershipForm from "./MembershipForm";
import { useLoadApp } from "../appCreator/api";
import { fetchApi } from "helpers/reactQueryApi";

export default function EditMembership() {
  const { membershipId } = useParams();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const externalItemId = membershipId;
  const store = useStore();
  const { data: app } = useLoadApp({
    externalAppId,
  });

  const { mutate: updateMembership } = useMutation(
    () =>
      fetchApi(`/members/${membershipId}`, {
        method: "PUT",
      }),
    {
      onSuccess: () => {
        location.replace(`/members/${membershipId}`);
        toastr.success(I18n.t("js.saving_successful"));
      },
      onError: () => {
        toastr.error(I18n.t("js.saving_failure"));
      },
    },
  );

  const onSubmit = async (values) => {
    await persistItem(app, store.dispatch, {
      externalAppId,
      externalItemId,
      values,
    });

    updateMembership({ membershipId });
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      title={I18n.t("js.apps.profile.edit.title")}
    />
  );
}
