import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import { GroupEntry, MemberEntry } from "components/directory/api";
import { isEmpty } from "lodash";
import {
  DisabledOption,
  MembershipRole,
  PermissionPickable,
  PermissionPickableWithDisabled,
  SystemRole,
  ValueType,
} from "components/shared/DirectoryPicker/types";
import { useQuery } from "react-query";

// Externally-visible signature, for compile time check that switch uses all types
export function throwBadType(entry: never): never;
// Implementation signature, called if invalid type is provided during runtime
export function throwBadType(entry: PermissionPickableWithDisabled) {
  throw new Error(
    "Unhandled option type: " + entry.type + " in " + JSON.stringify(entry),
  );
}

/**
 * Turns a given option value into a react element
 * If display attributes (like name) are missing, they are fetched by the react components
 *
 * @param entry
 */
export function formatOptionLabel(
  entry: ValueType | PermissionPickableWithDisabled,
) {
  if (!isOptionEnabled(entry)) return noOptionsMessage({ inputValue: "" });

  switch (entry.type) {
    case "member":
      return <MemberOptionLabel {...entry} />;

    case "group":
      return <GroupOptionLabel {...entry} />;

    case "system_role":
      return <SystemRoleOptionLabel {...entry} />;

    case "membership_role":
      return <MembershipRoleOptionLabel {...entry} />;
    default:
      throwBadType(entry);
  }
}

function MemberOptionLabel({ id, name }: MemberEntry) {
  const { data, isLoading } = useQuery<MemberEntry>(`/members/${id}`, {
    enabled: !name,
  });

  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={regular("user")}
        className="fa-fw mr-2"
      />
      {isLoading
        ? I18n.t("js.loading")
        : name || data?.name || I18n.t("js.memberships.deleted_name")}
    </>
  );
}

function GroupOptionLabel({ id, name }: GroupEntry) {
  const { data, isLoading } = useQuery<GroupEntry>(`/groups/${id}`, {
    enabled: !name,
  });

  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={regular("users")}
        className="fa-fw mr-2"
      />
      {isLoading
        ? I18n.t("js.loading")
        : `${name || data?.name || I18n.t("js.groups.unknown_group")} (${I18n.t(
            "js.member_select.all_group_members",
          )})`}
    </>
  );
}

function SystemRoleOptionLabel({ id, name }: SystemRole) {
  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={regular("computer-classic")}
        className="fa-fw mr-2"
      />
      {name || I18n.t(`js.permission_picker.system_roles.${id}`)}
    </>
  );
}

function MembershipRoleOptionLabel({
  id,
  name,
  parent_type,
  parent_id,
}: MembershipRole) {
  const { data: groupData, isLoading } = useQuery<GroupEntry>(
    `/groups/${parent_id}`,
    {
      enabled: parent_type == "group" && !name,
    },
  );
  let data: Pick<MembershipRole, "name"> | undefined;
  if (parent_type == "group")
    data = groupData?.membership_roles.find((r) => r.id == id);
  else data = Preload.current_network.membership_roles.find((r) => r.id == id);

  return (
    <>
      <FontAwesomeIcon
        key="icon"
        icon={regular("tag")}
        className="fa-fw mr-2"
      />
      {isLoading
        ? I18n.t("js.loading")
        : name || data?.name || I18n.t("js.permission_picker.unknown_role")}
    </>
  );
}

function isOptionEnabled(entry: ValueType): entry is PermissionPickable {
  return !("disabled" in entry);
}

export function isOptionDisabled(entry: ValueType): entry is DisabledOption {
  return !isOptionEnabled(entry);
}

export const noOptionsMessage = ({ inputValue }) =>
  isEmpty(inputValue)
    ? I18n.t("js.plugins.select2.enter_more_characters.one")
    : I18n.t("js.plugins.select2.no_match");
