import React, { useEffect, useState } from "react";
import { flatten, includes, map } from "lodash";
import { compose } from "recompose";
import store from "../../store";
import wrapProvider from "../../helpers/components/wrapProvider";
import preventDefault from "../../helpers/components/preventDefault";
import UnreadBadge from "components/shared/UnreadBadge";
import InvitesList from "components/invites/InvitesList";
import { useFeaturesUnreads } from "../../api/unreads";
import { useInvites } from "components/invites/api";
import { useInfiniteQuery } from "react-query";
import fetchPaginatedData from "helpers/api/fetchPaginatedData";

function useParticipations({ status, enabled }) {
  return useInfiniteQuery(
    ["participations", status],
    ({ pageParam = `/api/v1/participations?status=${status}&limit=20` }) =>
      fetchPaginatedData({ pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled,
    },
  );
}

function Invites() {
  const { data: unreads, refetch: reloadUnreads } = useFeaturesUnreads();
  const [activeTab, setActiveTab] = useState("open");
  const isInvitesTab = includes(["open", "expired", "obsolete"], activeTab);

  const {
    data: invitesData,
    isLoading: isLoadingInvites,
    refetch: reloadInvites,
    fetchNextPage: loadMoreInvites,
    hasNextPage: canLoadMoreInvites,
  } = useInvites({
    filter: "appointments",
    status: activeTab,
    enabled: isInvitesTab,
  });

  const {
    data: participationsData,
    isLoading: isLoadingParticipations,
    refetch: reloadParticipations,
    fetchNextPage: loadMoreParticipations,
    hasNextPage: canLoadMoreParticipations,
  } = useParticipations({
    status: activeTab,
    enabled: !isInvitesTab,
  });

  const [items, setItems] = useState(
    isInvitesTab
      ? {
          type: "invites",
          data: flatten(map(invitesData?.pages, (page) => page.data)),
        }
      : {
          type: "participations",
          data: flatten(map(participationsData?.pages, (page) => page.data)),
        },
  );

  function load() {
    isInvitesTab ? reloadInvites() : reloadParticipations();

    reloadUnreads();
  }

  useEffect(() => {
    const updateItems = () => {
      if (isInvitesTab) {
        setItems({
          type: "invites",
          data: flatten(map(invitesData?.pages, (page) => page.data)),
        });
      } else {
        setItems({
          type: "participations",
          data: flatten(map(participationsData?.pages, (page) => page.data)),
        });
      }
    };
    updateItems();
  }, [activeTab, invitesData, participationsData]);

  function loadMore(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    if (isInvitesTab) {
      loadMoreInvites();
    } else {
      loadMoreParticipations();
    }
  }

  return (
    <div className="tixxt-tabs-layout">
      <div className="tabs-region">
        <ul className="nav nav-tabs">
          <li className={activeTab === "open" ? "active" : ""}>
            <a onClick={preventDefault(() => setActiveTab("open"))} href="#">
              <div className="flex items-center">
                {I18n.t("js.calendars.appointments.invites.tabs.open")}
                {<UnreadBadge count={unreads?.Calendars} className="ml-1" />}
              </div>
            </a>
          </li>
          <li className={activeTab === "upcoming" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("upcoming"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.upcoming")}
            </a>
          </li>
          <li className={activeTab === "accepted" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("accepted"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.accepted")}
            </a>
          </li>
          <li className={activeTab === "declined" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("declined"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.declined")}
            </a>
          </li>
          <li className={activeTab === "expired" ? "active" : ""}>
            <a onClick={preventDefault(() => setActiveTab("expired"))} href="#">
              {I18n.t("js.calendars.appointments.invites.tabs.expired")}
            </a>
          </li>
          <li className={activeTab === "obsolete" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("obsolete"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.obsolete")}
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content-region">
        <InvitesList
          isLoading={isLoadingInvites || isLoadingParticipations}
          inviteType="appointments"
          inviteStatus={activeTab}
          invites={items}
          loadMore={loadMore}
          canLoadMore={canLoadMoreInvites || canLoadMoreParticipations || false}
          reloadInvites={load}
        />
      </div>
    </div>
  );
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(wrapProvider(store))(Invites);
