import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { compose, withState, withHandlers } from "recompose";
import Editor from "@draft-js-plugins/editor";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "draft-js/dist/Draft.css";

import "./configure"; // this configures the defaults
import markdownByEditorState from "components/shared/markdownEditor/utils/markdownByEditorState";
import editorStateByMarkdown from "components/shared/markdownEditor/utils/editorStateByMarkdown";
import createStyleMap from "components/shared/markdownEditor/styleMap";
import withBasicMarkdownPlugins from "components/shared/markdownEditor/withBasicMarkdownPlugins";

import "./MarkdownEditor.css";

function MarkdownEditor({
  key,
  onEditorStateChange,
  containerClasses,
  editorState,
  onSetEditorRef,
  plugins,
  pluginComponents,
  placeholder,
  focusEditor,
  readOnly,
  onFocus,
  onBlur,
  shouldFocus,
}) {
  useEffect(() => {
    if (shouldFocus) focusEditor();
  }, [shouldFocus]);

  return (
    <div
      className={classnames(
        "Composer object-content-input form-control px-2 py-1",
        containerClasses,
        { readOnly: readOnly },
      )}
    >
      <div className={"flex flex-col-reverse"}>
        <div onClick={focusEditor}>
          <Editor
            key={`editor-${key}`}
            ref={onSetEditorRef}
            name="content"
            placeholder={placeholder}
            editorState={editorState}
            onChange={onEditorStateChange}
            plugins={plugins}
            customStyleMap={createStyleMap()}
            spellCheck
            stripPastedStyles
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        <div className={"-mx-2 -mt-1 mb-2"}>
          <pluginComponents.Toolbar className={"-px-2"} />
        </div>
      </div>
      <div className="composer-statistics hidden sm:block">
        <ul className="unstyled inline clearfix flex flex-cols justify-end items-center gap-2">
          <li className="flex items-center justify-center">
            <pluginComponents.EmojiSelect />
          </li>
          <li
            className="flex items-center justify-center"
            title={I18n.t("js.composer.editor.undo.hint")}
          >
            <pluginComponents.UndoButton />
          </li>
          <li
            className="flex items-center justify-center"
            title={I18n.t("js.composer.editor.redo.hint")}
          >
            <pluginComponents.RedoButton />
          </li>
        </ul>
      </div>
      <div style={{ textAlign: "center" }}>
        {pluginComponents.InlineMentionSuggestions ? (
          <pluginComponents.InlineMentionSuggestions key="mentionSuggestions" />
        ) : null}
        <pluginComponents.EmojiSuggestions key="emojiSuggestions" />
      </div>
    </div>
  );
}

MarkdownEditor.propTypes = {
  key: PropTypes.string,
  content: PropTypes.string,
  onEditorStateChange: PropTypes.func,
  placeholder: PropTypes.string,
  containerClasses: PropTypes.string,
  editorState: PropTypes.object,
  onSetEditorRef: PropTypes.func,
  plugins: PropTypes.array,
  pluginComponents: PropTypes.object,
  focusEditor: PropTypes.func,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default compose(
  withBasicMarkdownPlugins,
  withState("key", "setKey", () => uuidv4()),
  withState("editorState", "setEditorState", ({ content }) =>
    editorStateByMarkdown(content),
  ),
  withState("editorRef", "setEditorRef", null),
  withHandlers({
    onEditorStateChange:
      ({ content, onChange, setEditorState }) =>
      (newEditorState) => {
        // Don't trigger change events to the outside if there is no content change
        const newContent = markdownByEditorState(newEditorState);
        if (content !== newContent) {
          setTimeout(() => onChange(newContent), 1);
        }
        setEditorState(newEditorState);
      },
    onSetEditorRef:
      ({ setEditorRef, setComposerRefCallback }) =>
      (ref) => {
        if (setComposerRefCallback) {
          setComposerRefCallback(ref);
        }
        setEditorRef(ref);
      },
    focusEditor:
      ({ editorRef }) =>
      (e) => {
        e?.preventDefault();
        if (editorRef) {
          setTimeout(() => editorRef.focus(), 1);
        }
      },
  }),
)(MarkdownEditor);
