import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

// For messages module
export function useFetchBaseMembershipData(membershipId, options = {}) {
  return useQuery(
    ["memberships", membershipId],
    () => (membershipId ? fetchApi(`/members/${membershipId}`) : null),
    options,
  );
}
