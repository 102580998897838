import React, { useEffect } from "react";
import Label from "components/shared/form/Label";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import Checkbox from "components/shared/form/fields/Checkbox";

export default function TeamsConfigField({
  register,
  control,
  setValue,
}: {
  register: UseFormRegister<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}) {
  const teamsSyncEnabled = useWatch({
    control,
    name: "teams_configuration.teams_sync_enabled",
  });
  const onlineMeeting = useWatch({
    control,
    name: "teams_configuration.online_meeting",
  });

  useEffect(() => {
    if (teamsSyncEnabled) setValue("teams_configuration.online_meeting", true);
  }, [teamsSyncEnabled]);

  useEffect(() => {
    if (!teamsSyncEnabled || !onlineMeeting)
      setValue("teams_configuration.bypass_lobby", false);
  }, [teamsSyncEnabled, onlineMeeting]);

  return (
    <div className="control-group">
      <Label label={I18n.t("js.calendars.appointment.microsoft_teams.label")} />
      <div className="controls border-box flex flex-col p-3 gap-y-">
        <div className="inline-controls">
          <Checkbox
            name="teams_configuration.teams_sync_enabled"
            register={register}
            label={I18n.t("js.calendars.teams_sync_enabled.checkbox.label")}
          />
        </div>
        {teamsSyncEnabled && (
          <div className="inline-controls ml-8">
            <Checkbox
              name="teams_configuration.online_meeting"
              register={register}
              label={I18n.t("js.calendars.online_meeting.checkbox.label")}
            />
            {onlineMeeting && (
              <>
                <Checkbox
                  name="teams_configuration.bypass_lobby"
                  register={register}
                  label={I18n.t("js.calendars.bypass_lobby.checkbox.label")}
                />
                <div>
                  <span className="text-muted text-sm">
                    {I18n.t("js.calendars.bypass_lobby.checkbox.hint")}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
