import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import ModuleSwitcher from "./ModuleSwitcher";
import { map } from "lodash";
import { useSelector } from "react-redux";
import { useFeaturesUnreads } from "api/unreads";
import { useLocation } from "helpers/tixxt-router";
import { State } from "../../@types";

type ModuleConfigLookup = {
  [moduleName: string]:
    | {
        title: string;
        icon: (opts: { duo: boolean; active: boolean }) => React.ReactNode;
        slug: string;
        url: string;
      }
    | undefined;
};
export const MODULE_LOOKUP: ModuleConfigLookup = {
  get Channels() {
    return {
      title: I18n.t("js.channels.module_name"),
      icon: ({ duo, active }) => (
        <FontAwesomeIcon
          icon={duo ? duotone("bullhorn") : light("bullhorn")}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "channels",
      url: "/channels",
    };
  },
  get Activitystreams() {
    return {
      title: I18n.t("js.activitystreams.module_name"),
      icon: ({ duo, active }) => (
        <FontAwesomeIcon
          // icon={duo ? duotone("signal-stream") : light("signal-stream")}
          icon={duo ? duotone("earth-europe") : light("earth-europe")}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "activities",
      url: "/activitystreams/all",
    };
  },
  get Messages() {
    return {
      title: I18n.t("js.messages.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          // icon={duo ? duotone("envelope") : light("envelope")}
          // icon={duo ? duotone("inbox-full") : light("inbox-full")}
          icon={duo ? duotone("inbox") : light("inbox")}
        />
      ),
      slug: "messages",
      url: "/messages",
    };
  },
  get Calendars() {
    return {
      title: I18n.t("js.calendars.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          // icon={duo ? duotone("calendar-range") : light("calendar-range")}
          icon={duo ? duotone("calendar-day") : light("calendar-day")}
        />
      ),
      slug: "appointments",
      url: "/appointments",
    };
  },
  get Tasks() {
    return {
      title: I18n.t("js.tasks.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          icon={duo ? duotone("circle-check") : light("circle-check")}
          // icon={duo ? duotone("square-check") : light("square-check")}
          // icon={duo ? duotone("list-check") : light("list-check")}
        />
      ),
      slug: "tasks",
      url: "/tasks/my",
    };
  },
  get Files() {
    return {
      title: I18n.t("js.files.module_name"),
      icon: ({ duo }) => (
        <FontAwesomeIcon
          // icon={duo ? duotone("folder") : light("folder")}
          // icon={duo ? duotone("folder-tree") : light("folder-tree")}
          // icon={duo ? duotone("cabinet-filing") : light("cabinet-filing")}
          // icon={duo ? duotone("briefcase") : light("briefcase")}
          icon={duo ? duotone("folder-open") : light("folder-open")}
        />
      ),
      slug: "files",
      url: "/files",
    };
  },
  get Directory() {
    return {
      title: "Kontakte",
      icon: ({ active }) => (
        <FontAwesomeIcon
          icon={light("user")}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "directory",
      url: "/directory",
    };
  },
  get More() {
    return {
      title: "Mehr",
      icon: ({ active }) => (
        <FontAwesomeIcon
          icon={light("ellipsis")}
          className={active ? "fa-swap-opacity" : ""}
        />
      ),
      slug: "more",
      url: "/more",
    };
  },
};

type Props = {
  activeModule?:
    | "channels"
    | "activities"
    | "messages"
    | "appointments"
    | "tasks"
    | "files"
    | "directory"
    | "more"
    | "info";
};

export default function TixxtModuleSwitcher({ activeModule }: Props) {
  const location = useLocation();
  const theme = useSelector((state: State) => state.theme);
  const duo = theme?.userbarIconType == "duotone";
  const currentNetwork = useSelector(
    (state: { network: { availableModules: string[] } }) => state.network,
  );
  const { data: unreads } = useFeaturesUnreads();

  return (
    <ModuleSwitcher className="userbar__module-switcher">
      {map(currentNetwork.availableModules, (moduleName, index) => {
        const module = MODULE_LOOKUP[moduleName];
        const notifications = unreads?.[moduleName];

        return (
          module && (
            <ModuleSwitcher.Module
              key={index}
              {...module}
              icon={module?.icon?.({
                duo,
                active: module.slug === activeModule,
              })}
              active={module.slug === activeModule}
              notifications={notifications}
            />
          )
        );
      })}

      {location.pathname.indexOf("/administration/designs") === 0 ||
      theme?.override ? (
        <ModuleSwitcher.Module
          title={"Design"}
          url={"/administration/designs"}
          active={location.pathname.indexOf("/administration/designs") === 0}
          icon={
            duo ? (
              <FontAwesomeIcon icon={duotone("brush")} />
            ) : (
              <FontAwesomeIcon icon={light("brush")} />
            )
          }
        />
      ) : null}

      <ModuleSwitcher.Module
        className="module-switcher__footer"
        key="info"
        title="Info"
        url="/imprint"
        active={activeModule === "info"}
        icon={
          <FontAwesomeIcon
            icon={duo ? duotone("circle-info") : light("circle-info")}
            className={activeModule === "info" ? "fa-swap-opacity" : ""}
          />
        }
      />
    </ModuleSwitcher>
  );
}
