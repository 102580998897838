import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FileIcon from "components/shared/FileIcon";
import { addDays, isAfter } from "date-fns";
import { AppointmentFile } from "../../@types/appointments";
import { includes } from "lodash";
import classNames from "classnames";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export default function FileRow(file: AppointmentFile) {
  const [showLockAction, setShowLockAction] = useState(false);
  const [isLocked, setIsLocked] = useState(
    file.locked_until
      ? isAfter(new Date(file.locked_until), new Date())
      : false,
  );

  const { mutate: lock } = useMutation(
    () =>
      fetchApi(`/api/storage/${file.url}/lock`, {
        method: "PUT",
        body: {
          locked_unitl: addDays(
            new Date(),
            Preload.current_network.config.files.default_lock_period_in_days,
          ),
        },
      }),
    {
      onSuccess: () => {
        setShowLockAction(false);
        setIsLocked(true);
      },
    },
  );

  const isOpenable =
    includes(["application/pdf", "text/html"], file.content_type) ||
    file.content_type?.indexOf("image/") === 0 ||
    file.content_type?.indexOf("text/") === 0;

  const resourceUrl = `/api/storage/files/${file.id}/get/${encodeURIComponent(
    file.original_filename || file.name,
  )}?_=${
    file.updated_at ? new Date(file.updated_at).valueOf() : Date.now().valueOf()
  }`;

  return (
    <>
      <div
        className={classNames("file-infos flex pt-2 pb-1 items-center gap-2", {
          hidden: showLockAction,
        })}
      >
        {!file.name && !file.url ? (
          <>
            <span className="fa-layers fa-fw shrink-0">
              <FontAwesomeIcon icon={solid("file")} size="xl" />
              <FontAwesomeIcon
                className="text-white"
                icon={regular("trash-can")}
                size="xl"
                transform="shrink-5 down-3"
              />
            </span>
            <span className="text-muted grow">
              {I18n.t("js.files.show.deleted_file_name")}
            </span>
          </>
        ) : (
          <>
            <FileIcon extension={file.extension} />
            <a
              href={`files/${file.id}`}
              title={file.name}
              className="grow truncate"
            >
              {isLocked && (
                <FontAwesomeIcon
                  className="mr-1 text-normal"
                  title={I18n.t("js.files.show.simple_lock_hint")}
                  icon={regular("lock")}
                />
              )}
              {file.name}
            </a>
          </>
        )}
        <span className="file-actions flex gap-2 shrink-0">
          {isOpenable && (
            <a
              className="muted decoration-0"
              href={resourceUrl}
              target="_blank"
              title={I18n.t("js.files.show.open_file")}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={regular("eye")} />
            </a>
          )}
          <a
            className="muted decoration-0"
            href={`${resourceUrl}&download`}
            onClick={() => {
              file.can.lock && !isLocked && setShowLockAction(true);
            }}
            target="_blank"
            title={I18n.t("js.files.show.download_file")}
            aria-label={I18n.t("js.files.show.download_file")}
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={regular("arrow-down-to-bracket")} />
          </a>
        </span>
      </div>
      <div
        className={classNames("file-lock flex pt-2 pb-2", {
          hidden: !showLockAction,
        })}
      >
        <span className="file-lock-text flex flex-1 ml-7">
          {I18n.t("js.files.show.lock_on_download_hint_short")}
          <a
            className="ml-2"
            data-action="lock"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              lock();
            }}
          >
            {I18n.t("js.files.show.lock_on_download_yes")}
          </a>
        </span>
        <span className="file-actions">
          <a
            href="#"
            tabIndex={-1}
            aria-label={I18n.t("js.no_action")}
            onClick={(e) => {
              e.preventDefault();
              setShowLockAction(false);
            }}
          >
            <FontAwesomeIcon icon={regular("times")} />
          </a>
        </span>
      </div>
    </>
  );
}
