import React, { useState } from "react";
import {
  get,
  reduce,
  isEmpty,
  values,
  countBy,
  sortedIndexBy,
  lowerCase,
  map,
  concat,
  sortBy,
} from "lodash";
import { NameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import { Participant } from "../../@types/appointments";

const SMALL_BREAKPOINT = 640;

function StatusIcon({
  status,
  className,
}: {
  status: string;
  className: string;
}) {
  switch (status) {
    case "open":
    case "invited":
      return (
        <span className={`fa-layers ${className}`}>
          <FontAwesomeIcon
            className={"text-white w-3"}
            icon={solid("circle")}
          />
          <FontAwesomeIcon
            className={"text-muted w-3"}
            icon={solid("adjust")}
          />
        </span>
      );
    case "accepted":
      return (
        <FontAwesomeIcon
          className={`accept-invite-text w-3 ${className}`}
          icon={solid("circle")}
        />
      );
    default:
      return (
        <span className={`fa-layers ${className}`}>
          <FontAwesomeIcon
            className={"text-white w-3"}
            icon={solid("circle")}
          />
          <FontAwesomeIcon
            className={"text-danger w-3"}
            icon={regular("circle")}
          />
        </span>
      );
  }
}

type RoleArgs = {
  id: string;
  name: string;
  plural_name: string;
  participants: Participant[];
  display_type: string;
};

function Role({ name, plural_name, participants, display_type }: RoleArgs) {
  const [collapsed, setCollapsed] = useState(
    display_type === "grouped" || window.innerWidth < SMALL_BREAKPOINT,
  );

  return (
    <div className={classNames({ hidden: isEmpty(participants) })}>
      <h3
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
        className={"font-bold mb-4"}
      >
        {name || plural_name} ({countBy(participants, "status").accepted || 0}){" "}
        <FontAwesomeIcon
          className={classNames("text-muted transition-transform fa-xs ml-1", {
            "rotate-90": !collapsed,
          })}
          icon={regular("chevron-right")}
        />
      </h3>
      <ul
        className={classNames("membership-list participant-list", {
          hidden: collapsed,
        })}
      >
        {participants.map((p) => (
          <li
            key={p.id || "me"}
            className={"flex gap-2 items-center relative py-2"}
          >
            <img
              className={"member-image w-7 h-7"}
              src={get(p, "membership.images.40x40@2")}
              alt={""}
              aria-hidden
            />
            <StatusIcon status={p.status} className={"absolute top-5 left-5"} />
            {get(p, "membership.path") ? (
              <a
                href={get(p, "membership.path")}
                className="truncate"
                title={get(p, "membership.name")}
              >
                <NameWithProfileField
                  name={get(p, "membership.name")}
                  detectionProfileField={get(
                    p,
                    "membership.detection_profile_field",
                  )}
                  isExternal={get(p, "membership.is_external")}
                />
              </a>
            ) : (
              <NameWithProfileField
                name={get(p, "membership.name")}
                detectionProfileField={get(
                  p,
                  "membership.detection_profile_field",
                )}
                isExternal={get(p, "membership.is_external")}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

// Inserts given participant into list maintaining sort by name and status
const statusToSort = { accepted: 0, rejected: 1, declined: 1, invited: 2 };
const pushParticipant = (list: Participant[], participant: Participant) => {
  list.splice(
    sortedIndexBy(list, participant, (p) =>
      lowerCase(
        `${statusToSort[p.status]}${get(p, "membership.last_name")}${get(
          p,
          "membership.first_name",
        )}`,
      ),
    ),
    0,
    participant,
  );
};

type ParticipantsListArgs = {
  participants: Participant[];
  showParticipants: boolean;
  consumersCount: number;
  teamsOnlyParticipantsCount: number;
};

export default function ParticipantsList({
  participants,
  showParticipants,
  consumersCount,
  teamsOnlyParticipantsCount,
}: ParticipantsListArgs) {
  let roles: RoleArgs[] = [];
  if (showParticipants) {
    const defaultRole = {
      id: "default",
      name: "",
      display_type: "",
      plural_name: I18n.t("js.calendars.appointment.rsvp.guests"),
      participants: [],
    };
    const participantsByRole = reduce<Participant, { [key: string]: RoleArgs }>(
      participants,
      (obj, participant) => {
        if (isEmpty(participant.roles)) {
          pushParticipant(defaultRole.participants, participant);
        } else {
          participant.roles.forEach((role) => {
            obj[role.id] || (obj[role.id] = { ...role, participants: [] });
            pushParticipant(obj[role.id].participants, participant);
          });
        }
        return obj;
      },
      {},
    );

    roles = concat(
      [defaultRole],
      sortBy(
        values(participantsByRole),
        Helpers.naturalSortByObjectKey("plural_name"),
      ),
    );
  }

  return (
    <div className={"space-y-6"}>
      {map(roles, (role) => (
        <Role {...role} key={role.id} />
      ))}
      {consumersCount > 0 ? (
        <p>
          {I18n.t("administration.consumer_manager.consumer_list_title", {
            count: consumersCount,
          })}
        </p>
      ) : null}
      {teamsOnlyParticipantsCount > 0 ? (
        <p>
          {I18n.t(
            "administration.consumer_manager.teams_participants_list_title",
            { count: teamsOnlyParticipantsCount },
          )}
        </p>
      ) : null}
    </div>
  );
}
