// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Datepicker as OriginalDatepicker } from "vanillajs-datepicker";

export function getDefaultOptions() {
  OriginalDatepicker.locales[I18n.currentLocale()] = I18n.t(
    "js.plugins.datepicker",
  );

  return {
    weekStart: 1,
    buttonClass: "btn btn-light",
    format: I18n.t("js.plugins.Locale.Formats.datepicker_date"),
    clearBtn: true,
    autohide: true,
    calendarWeeks: true,
    language: I18n.currentLocale(),
  };
}

// Auto apply our default options
export default function Datepicker(elem, options: object = {}): void {
  return new OriginalDatepicker(elem, { ...getDefaultOptions(), ...options });
}
