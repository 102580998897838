import React, { Fragment, useCallback } from "react";
import { map, castArray, isArray, compact, debounce } from "lodash";
import { Field } from "redux-form";
import AsyncSelect from "react-select/async";

import styles from "../../appCreator/properties/edit/editFields.module.css";
import { useMutation } from "react-query";
import { stringify } from "query-string";
import { fetchApi } from "helpers/reactQueryApi";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return { id: value.value, name: value.label };
};

const formatValue = (state) =>
  map(compact(castArray(state)), ({ id, name }) => ({
    value: id,
    label: name,
  }));

interface SelectAdapterProps {
  input?: Record<PropertyKey, unknown>;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
}

function SelectAdapter({
  input,
  required,
  id,
  disabled,
  className,
  multiple,
}: SelectAdapterProps) {
  const { mutate: loadOptions } = useMutation(
    ({
      q,
    }: {
      q: string;
      callback: (options: { value: string; label: string }[]) => void;
    }) => fetchApi(`/directory?${stringify({ q, groups_only: true })}`),
    {
      onSuccess: (
        data: { entries: { id: string; name: string }[] },
        variables,
      ) => {
        variables.callback(formatValue(data?.entries));
      },
    },
  );

  const debouncedLoadOptions = useCallback(
    debounce((value, callback) => loadOptions({ q: value, callback }), 375),
    [loadOptions],
  );

  const props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.group_select.placeholder"),
    noOptionsMessage: () => I18n.t("plugins.select2.no_match"),
    className,
    loadOptions: debouncedLoadOptions,
    isMulti: multiple,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
  };

  return (
    <Fragment>
      <div className="remove-input-txt-border">
        <AsyncSelect
          {...props}
          className="form-select-container"
          classNamePrefix="form-select"
          unstyled
        />
      </div>
    </Fragment>
  );
}

interface GroupSelectFieldProps {
  name: string;
  multiple: boolean;
  required?: boolean;
  disabled?: boolean;
  id?: string;
}

function GroupSelectField({
  name,
  required,
  disabled,
  id,
  multiple,
}: GroupSelectFieldProps) {
  return (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      disabled={disabled}
      normalize={normalizeValue}
      format={formatValue}
      multiple={multiple}
      id={id}
      props={{
        className: styles.Selection,
      }}
    />
  );
}

export default GroupSelectField;
