import React from "react";
import { useParams, useRoutes } from "helpers/tixxt-router";
import GroupNavigation from "components/layout/GroupNavigation";
import { GroupNavigationPortal } from "components/layout/portals";

/* This is used together with the backbone marionette frontend */
export default function TixxtGroupNavigation() {
  const params = useParams();
  const groupSlug = params.group_slug || params.groupSlug || params.group_id;

  if (groupSlug) return <GroupNavigation groupSlug={groupSlug} />;

  return null;
}

/* This is used to render the group navigation on backend rendered pages */
export function StaticTixxtGroupNavigation() {
  const elem = useRoutes([
    { path: "/groups/:groupSlug", element: <TixxtGroupNavigation /> },
    { path: "/groups/:groupSlug/*", element: <TixxtGroupNavigation /> },
  ]);

  if (!elem || Preload.current_group?.externally_visible) return null;

  return <GroupNavigationPortal>{elem}</GroupNavigationPortal>;
}
