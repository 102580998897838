import { includes, isEmpty, isPlainObject } from "lodash";

export const csrfTokenHeader = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return { "X-CSRF-Token": meta ? meta.getAttribute("content") : null };
};

export const buildHeaders = ({ method, body, headers }) => {
  let h = { Accept: "application/json", "Cache-Control": "no-store" };

  if (includes(["POST", "PUT", "DELETE", "PATCH"], method)) {
    h = { ...h, ...csrfTokenHeader() };
  }

  if (!isEmpty(body)) {
    h["Content-Type"] = "application/json";
  }

  if (isPlainObject(headers)) {
    return h;
  } else {
    return { ...h, ...headers };
  }
};

export const okJson = async (response: {
  status: number;
  ok: boolean;
  json: () => any;
}) => {
  if (response.status === 204) return null;

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(I18n.t("js.generic_error"));
  }
};

export async function fetchApi(
  url: string,
  options:
    | {
        method?: string;
        body?: any;
        headers?: object;
        parsePaginationHeaders?: boolean;
      }
    | undefined = {},
) {
  const { method = "GET", body, headers = {} } = options;

  const response = await fetch(url, {
    method,
    headers: buildHeaders({ method, body, headers }),
    credentials: "include",
    body: body ? JSON.stringify(body) : null,
  });

  const payload = await okJson(response);
  if (options.parsePaginationHeaders) {
    return {
      payload,
      pagination: {
        totalPages: Number(response.headers.get("X-Total-Pages")),
      },
    };
  } else {
    return payload;
  }
}
