import React, { Fragment, useCallback } from "react";
import {
  map,
  each,
  uniqBy,
  castArray,
  isArray,
  compact,
  debounce,
} from "lodash";
import { Field } from "redux-form";
import AsyncSelect from "react-select/async";

import { showNameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import styles from "../appCreator/properties/edit/editFields.module.css";
import FieldError from "components/appCreator/items/form/FieldError";
import { useMutation } from "react-query";
import { stringify } from "query-string";
import { fetchApi } from "helpers/reactQueryApi";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return {
    id: value.value,
    displayValue: value.label,
    type: value.type,
  };
};

const formatValue = (state) =>
  map(compact(castArray(state)), ({ id, displayName, displayValue, type }) => ({
    value: id,
    label: displayName || displayValue,
    type: type,
  }));

const formatResponse = (results) => {
  const members: Array<{ label: string; value: string; type: string }> = [];
  const groups: Array<{
    label: string;
    value: string;
    category: string;
    type: string;
  }> = [];
  let groupCategories: Array<{ label: string; id: string }> = [];

  each(results, (result) => {
    if (result.type === "member") {
      members.push({
        label: showNameWithProfileField({
          name: result.name,
          detectionProfileField: result.detection_profile_field,
          isExternal: result.is_external,
        }),
        value: result.id,
        type: "member",
      });
    } else {
      groupCategories.push({
        label: result.category.name,
        id: result.category.id,
      });
      groups.push({
        label: result.name,
        value: result.id,
        category: result.category.id,
        type: "group",
      });
    }
  });

  groupCategories = uniqBy(groupCategories, "id");
  const finalResults: Array<{ label: string; options: typeof members }> = [];

  if (members.length > 0) {
    finalResults.push({
      label: I18n.t("js.directory.title.members"),
      options: members,
    });
  }
  if (groups.length > 0) {
    each(groupCategories, (cat) => {
      finalResults.push({
        label: cat.label,
        options: groups.filter((group) => group.category === cat.id),
      });
    });
  }

  return finalResults;
};

interface SelectAdapterTypes {
  input?: Record<PropertyKey, unknown>;
  required?: boolean;
  id: string;
  disabled: boolean;
  className?: string;
  multiple: boolean;
  meta?: { error?: string };
  extraQueryParams?: {
    only_my_groups?: boolean;
    only_my_admin_groups?: boolean;
  };
}

function SelectAdapter({
  input,
  required,
  id,
  disabled,
  className,
  multiple,
  meta = {},
  extraQueryParams = {},
}: SelectAdapterTypes) {
  const { mutate: loadOptions } = useMutation(
    ({
      q,
    }: {
      q: string;
      callback: (
        options: {
          label: string;
          options: { value: string; label: string; type: string }[];
        }[],
      ) => void;
    }) => fetchApi(`/directory?${stringify({ q, ...extraQueryParams })}`),
    {
      onSuccess: (
        data: { entries: { id: string; name: string }[] },
        variables,
      ) => {
        variables.callback(formatResponse(data?.entries));
      },
    },
  );

  const debouncedLoadOptions = useCallback(
    debounce((value, callback) => loadOptions({ q: value, callback }), 375),
    [loadOptions],
  );

  const props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.member_select.placeholder"),
    noOptionsMessage: () => I18n.t("plugins.select2.no_match"),
    className,
    loadOptions: debouncedLoadOptions,
    isMulti: multiple,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
  };

  return (
    <Fragment>
      <div className="remove-input-txt-border">
        <AsyncSelect
          {...props}
          className="form-select-container"
          classNamePrefix="form-select"
          unstyled
        />
      </div>
      {meta.error && <FieldError error={meta.error} />}
    </Fragment>
  );
}

interface MembershipAndGroupFieldTypes extends SelectAdapterTypes {
  name: string;
  multiple: boolean;
}
function MembershipAndGroupField({
  name,
  required,
  disabled,
  id,
  multiple,
  extraQueryParams,
}: MembershipAndGroupFieldTypes) {
  return (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      disabled={disabled}
      normalize={normalizeValue}
      format={formatValue}
      multiple={multiple}
      id={id}
      props={{
        className: `${styles.Membership} property-${name}`,
        extraQueryParams,
      }}
    />
  );
}

export default MembershipAndGroupField;
