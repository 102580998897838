import React from "react";
import { useMutation, UseMutationResult } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import invariant from "invariant";
import { Group, State } from "../../../@types";
import { useDispatch, useSelector } from "react-redux";
import { toggleFavorited } from "reducers/groups";
import ToggleFavoriteButton from "components/shared/favoriteButtons/ToggleFavoriteButton";

type FavoriteStatus = {
  favorited: boolean;
} | null;
type MutateVariables = {
  favorite: boolean /* Wether to set or unset favorite status */;
};

function useToggleGroupFavorite(
  groupSlug: string,
): [
  Group,
  UseMutationResult<FavoriteStatus, unknown, MutateVariables, unknown>,
] {
  const group = useSelector((state: State) => state.groups.bySlug[groupSlug]);
  const dispatch = useDispatch();
  invariant(group, `Group ${groupSlug} not preloaded`);

  const mutation = useMutation<FavoriteStatus, unknown, MutateVariables>({
    mutationFn({ favorite }) {
      return fetchApi(`/api/v1/groups/${groupSlug}/favorite`, {
        method: favorite ? "POST" : "DELETE",
      });
    },
    onSuccess(data) {
      dispatch(
        toggleFavorited({
          groupSlug,
          favorited: data?.favorited || false,
        }),
      );
    },
  });

  return [group, mutation];
}

export default function ToggleGroupFavoriteButton({
  groupSlug,
}: {
  groupSlug: string;
}) {
  const [{ favorited }, { mutate, isLoading }] =
    useToggleGroupFavorite(groupSlug);

  return (
    <ToggleFavoriteButton
      title={
        favorited
          ? I18n.t("js.page_title.remove_group_from_favorites")
          : I18n.t("js.page_title.add_group_to_favorites")
      }
      toggleFavorited={() => mutate({ favorite: !favorited })}
      isLoading={isLoading}
      favorited={favorited}
    />
  );
}
