import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function AssignedMember({ id, imageId, displayValue }) {
  return (
    <a
      className="flex gap-1"
      href={`?assignee_id=${id}&assignee_name=${encodeURIComponent(
        displayValue,
      )}&image_id=${imageId}`}
    >
      <img
        className="card-assignment-profile-image rounded-full"
        alt={`${displayValue}`}
        src={
          !isEmpty(imageId)
            ? `/api/storage/images/${imageId}/get/25x25`
            : Assets["profile_25x25.jpg"]
        }
      />
      {displayValue}
    </a>
  );
}

AssignedMember.propTypes = {
  id: PropTypes.string,
  displayValue: PropTypes.string,
  imageId: PropTypes.string,
};

export default AssignedMember;
