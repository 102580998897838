import { get } from "lodash";
import { groupSelector, networkSelector } from "./environment";

// Select the currentFolder from state
export const currentFolderMetaSelector = (state) =>
  get(state, ["folders", "currentFolder"]);

export const rootFolderIdSelector =
  ({ groupSlug }) =>
  (state) =>
    get(
      groupSlug ? groupSelector(state, groupSlug) : networkSelector(state),
      "root_folder_id",
    );
