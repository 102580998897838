import React from "react";
import { Control, Controller } from "react-hook-form";

import Label from "components/shared/form/Label";
import DirectoryPicker from "components/shared/DirectoryPicker";

export default function ContactsField({ control }: { control: Control<any> }) {
  return (
    <div className="control-group remove-input-txt-border">
      <Label label={I18n.t("js.calendars.appointment.contacts.label")} />
      <div className="controls">
        <Controller
          name="contacts"
          control={control}
          render={({ field }) => (
            <DirectoryPicker {...field} multi endpoint={"/directory/members"} />
          )}
        />
      </div>
    </div>
  );
}
