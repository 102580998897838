import React, { useState } from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import FileUploadField from "components/shared/form/fields/FileUploadField";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TextInput from "components/shared/form/fields/TextInput";
import CategorySelectionField from "components/appointments/form/CategorySelectionField";
import ContactsField from "components/appointments/form/ContactsField";
import AttendeeConfigurationField from "components/appointments/form/AttendeeConfigurationField";
import ApplicationConfigurationField from "components/appointments/form/ApplicationConfigurationField";

export default function EventSettings({
  control,
  register,
  setValue,
}: {
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}) {
  const isEvent = useWatch({ control, name: "is_event" });
  const [showEventSettings, setShowEventSettings] = useState(isEvent);

  return (
    <div className="flex flex-col">
      <button
        className={classNames(
          "flex px-3 pt-1.5 gap-2 items-center mb-4 justify-between hover:bg-neutral/10",
          {
            "border-b border-b-neutral": !showEventSettings,
          },
        )}
        onClick={(e) => {
          e.preventDefault();
          setShowEventSettings(!showEventSettings);
          setValue("is_event", !showEventSettings);
        }}
      >
        <h4>{I18n.t("js.calendars.appointment.toggle_event_settings")} </h4>
        <FontAwesomeIcon
          className="mb-2"
          icon={showEventSettings ? solid("chevron-up") : solid("chevron-down")}
        />
      </button>
      {showEventSettings && (
        <div className="form-horizontal">
          <FileUploadField
            control={control}
            name={"event_image"}
            label={I18n.t("js.calendars.appointment.logo.label")}
            options={{ isImage: true, storageDirectory: "images" }}
          />
          <CategorySelectionField control={control} setValue={setValue} />
          <TextInput
            name="consultants"
            register={register}
            label={I18n.t("js.calendars.appointment.consultants.label")}
          />
          <AttendeeConfigurationField register={register} />
          <ApplicationConfigurationField
            register={register}
            control={control}
          />
          <ContactsField control={control} />
          <TextInput
            name="organizer"
            register={register}
            placeholder={I18n.t(
              "js.calendars.appointment.organizer.placeholder",
            )}
            label={I18n.t("js.calendars.appointment.organizer.label")}
            multiline
          />
        </div>
      )}
    </div>
  );
}
