import React, { useState } from "react";
import { map, get, isEmpty, groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import { useQuery } from "react-query";
import { stringify } from "query-string";

import ChatHeader from "../shared/ChatHeader";
import GroupedItems from "../shared/GroupedItems";
import Tile from "./Tile";
import { BackToRootButton } from "../shared/BackButton";

function useAvailableChats(q) {
  return useQuery(
    `/directory?${stringify({
      q,
      only_my_groups: true,
      only_chat_enabled: true,
    })}`,
    { refetchOnWindowFocus: false },
  );
}

const getGroupCategoryName = (c) => get(c, ["category", "plural_name"]);
const categoryOrMemberType = (memberOrGroup) =>
  memberOrGroup.type === "member"
    ? "member"
    : getGroupCategoryName(memberOrGroup);

export default function NewChat() {
  const [query, setQuery] = useState("");
  const { data, isLoading } = useAvailableChats(query);

  const { member, ...groupsByCategoryName } = groupBy(
    data?.entries,
    categoryOrMemberType,
  );

  function submitSearch(e) {
    e.preventDefault();
    setQuery(e.target.q.value);
  }

  return (
    <>
      <ChatHeader
        title={I18n.t("js.chat.new.headline")}
        ButtonLeft={<BackToRootButton />}
      />
      <form
        className={classNames("px-3 py-2 chatNewForm flex overflow-visible", {
          "mt-14": window.isApp,
        })}
        onSubmit={submitSearch}
      >
        <div className={"input-group"}>
          <input
            type="text"
            name="q"
            placeholder={I18n.t("js.chat.new.search_placeholder")}
            autoFocus
            className={"grow"}
          />
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon
                className={"fa-spin"}
                icon={regular("spinner")}
              />
            ) : (
              <FontAwesomeIcon icon={regular("search")} />
            )}
          </button>
        </div>
      </form>
      {isEmpty(member) || query.length === 0 ? null : (
        <GroupedItems
          key="members"
          items={member}
          renderItemComponent={(item, index) => (
            <Tile key={index} type="member" {...item} />
          )}
          title={I18n.t("js.chat.new.users")}
          allowToggle={true}
        />
      )}
      {map(groupsByCategoryName, (groups, name) => [
        <GroupedItems
          key={name}
          items={groups}
          renderItemComponent={(item, index) => (
            <Tile key={index} type="group" {...item} />
          )}
          title={name}
          allowToggle={true}
        />,
      ])}
    </>
  );
}
