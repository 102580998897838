import React, { useState } from "react";
import { get, map } from "lodash";
import { fetchApi } from "helpers/reactQueryApi";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAcceptInvite, useDeclineInvite } from "components/invites/api";

type Invitable = {
  id: string;
  slug: string;
  name: string;
  access: string;
  teaser?: string;
  images?: string;
  member_count?: number;
  activity_count?: number;
};

type Author = {
  id: string;
  name: string;
  detection_profile_field?: string;
};

type GroupInvite = {
  id: string;
  invitable: Invitable;
  author: Author;
  additional_message: string;
};

type GroupInvites = {
  groupInvites: GroupInvite[];
};

function useFetchGroupInvites(options = {}) {
  return useQuery<GroupInvites[]>(
    ["groupInvites"],
    () => fetchApi("/api/v1/invites?filter=groups&status=open&limit=50"),
    options,
  );
}

function GroupInviteActions({
  groupInviteId,
  reloadGroupInvites,
}: {
  groupInviteId: string;
  reloadGroupInvites: () => void;
}) {
  const [inviteStatus, setInviteStatus] = useState("open");
  const { mutate: declineInvite } = useDeclineInvite({
    onSuccess: () => {
      reloadGroupInvites();
    },
  });
  const { mutate: acceptInvite } = useAcceptInvite({
    onSuccess: () => {
      reloadGroupInvites();
    },
  });

  const buttons = (
    <div className="btn-group">
      <button
        onClick={() => {
          acceptInvite({ inviteId: groupInviteId });
          setInviteStatus("accepted");
        }}
        className="btn btn-success"
        aria-label="accept invite"
      >
        <i className="fa fa-plus mr-1" />
        {I18n.t("js.invites.actions.accept")}
      </button>
      <button
        onClick={() => {
          declineInvite({ inviteId: groupInviteId });
          setInviteStatus("declined");
        }}
        className="btn btn-danger"
        aria-label="reject invite"
      >
        <i className="fa fa-xmark mr-1" />
        {I18n.t("js.invites.actions.reject")}
      </button>
    </div>
  );

  const hint = (
    <div className="group-invite-reaction">
      {["accepted", "rejected"].includes(inviteStatus)
        ? I18n.t(`js.calendars.groups.invite.${inviteStatus}`)
        : null}
    </div>
  );

  if (inviteStatus === "open") {
    return buttons;
  } else {
    return hint;
  }
}

function GroupInvite({
  id,
  invitable,
  author,
  additional_message,
  reloadGroupInvites,
}: GroupInvite & { reloadGroupInvites: () => void }) {
  function checkIfPresent(value) {
    return value !== undefined && value !== null;
  }

  return (
    <li key={id} className="media space-x-5 py-6 px-1.5 justify-between ">
      <div className="media-object h-16 w-16">
        {invitable.images ? (
          <img
            className="w-[inherit] h-[inherit]"
            src={get(invitable.images, [1, "url"])}
            alt="group logo"
          />
        ) : (
          <FontAwesomeIcon
            icon={solid("users")}
            className="fa-2xl text-gray-400 mx-auto block"
          />
        )}
      </div>
      <div className="media-body -mt-1">
        {invitable.access === "hidden" ? (
          <div className="text-lg">{invitable.name}</div>
        ) : (
          <a
            className="media-heading"
            href={`/groups/${invitable.slug}`}
            aria-label={`${invitable.name} link`}
          >
            <div className="flex gap-1 items-center">
              {invitable.access === "closed" ? (
                <FontAwesomeIcon icon={solid("lock")} />
              ) : undefined}
              <div className="text-lg">{invitable.name}</div>
            </div>
          </a>
        )}
        {author.name ? (
          <div>
            {I18n.t("js.invites.show.invited_by")}
            <a
              className="text-muted text-sm ml-1"
              href={`/members/${author.id}`}
              aria-label={`${author.name} profile link`}
            >
              {author.name}
              {author.detection_profile_field
                ? ` ${author.detection_profile_field}`
                : undefined}
            </a>
          </div>
        ) : undefined}
        {additional_message ? (
          <div>
            <div className="font-semibold mt-4 mb-1">
              {I18n.t("js.groups.group.personal_message")}
            </div>
            <div className="my-1 markdown-content prose whitespace-pre-line break-words">
              {additional_message}
            </div>
          </div>
        ) : undefined}
        {checkIfPresent(invitable.member_count) &&
        checkIfPresent(invitable.activity_count) ? (
          <div className="text-muted text-sm mb-2">
            {`${
              invitable.member_count === 1
                ? I18n.t("js.directory.group_row.member_count.one")
                : I18n.t("js.directory.group_row.member_count.other", {
                    count: invitable.member_count,
                  })
            }, ${
              invitable.activity_count === 1
                ? I18n.t("js.directory.group_row.activity.one")
                : I18n.t("js.directory.group_row.activity.other", {
                    count: invitable.activity_count,
                  })
            }`}
          </div>
        ) : undefined}
        {invitable.teaser ? (
          <div className="my-5 markdown-content prose whitespace-pre-line break-words">
            {invitable.teaser}
          </div>
        ) : undefined}
      </div>
      <div className="shrink-0">
        <GroupInviteActions
          groupInviteId={id}
          reloadGroupInvites={reloadGroupInvites}
        />
      </div>
    </li>
  );
}

export default function GroupInvites() {
  const {
    isLoading,
    data: groupInvites,
    refetch: reloadGroupInvites,
  } = useFetchGroupInvites();

  return (
    <div className="group-invites">
      <div className="text-lg font-semibold tixxt__pagetitle">
        {I18n.t("js.groups.invites.title")}
      </div>
      <div className="invites-content">
        {!isLoading && groupInvites && groupInvites.length > 0 ? (
          <ul className="unstyled media-list groups-list -mt-5">
            {map(groupInvites, (groupInvite: GroupInvite) => (
              <GroupInvite
                {...groupInvite}
                reloadGroupInvites={reloadGroupInvites}
              />
            ))}
          </ul>
        ) : (
          <div className="alert alert-info">
            {I18n.t("js.directory.group_row.no_invites")}
          </div>
        )}
      </div>
    </div>
  );
}
