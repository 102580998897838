import React from "react";
import { useParams } from "helpers/tixxt-router";
import { useBlog } from "components/blogs/api";
import BlogForm from "components/blogs/form/BlogForm";
import PageTitle from "components/layout/PageTitle";

export default function BlogEditPage() {
  const { id } = useParams();
  const { data: blog, isLoading } = useBlog(id);

  if (isLoading) {
    return <div>{I18n.t("js.loading")}</div>;
  }

  return (
    <div>
      <PageTitle title={I18n.t("js.blog.edit.title")} />
      {blog && <BlogForm blog={blog} />}
    </div>
  );
}
