import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import useTooltip from "../../../hooks/useTooltip";

type ToggleFavoriteButtonArgs = {
  title: string;
  toggleFavorited: () => void;
  isLoading: boolean;
  favorited: boolean;
};

export default function ToggleFavoriteButton({
  title,
  isLoading,
  toggleFavorited,
  favorited,
}: ToggleFavoriteButtonArgs) {
  const tooltipRef = useTooltip<HTMLButtonElement>([favorited]);

  return (
    <button
      ref={tooltipRef}
      data-bs-toggle="tooltip"
      title={title}
      className={classNames("btn btn-sm", {
        "cursor-wait": isLoading,
        "text-favorite hover:text-favorite": favorited,
        "text-gray-200 hover:text-favorite/50": !favorited,
      })}
      disabled={isLoading}
      onClick={toggleFavorited}
    >
      <FontAwesomeIcon icon={favorited ? solid("star") : regular("star")} />
    </button>
  );
}
