import React, { useState } from "react";
import { Appointment } from "../../../@types/appointments";
import { map, take } from "lodash";

import FileRow from "components/shared/FileRow";

type AppointmentEventFilesArgs = {
  eventFiles: Appointment["event_files"];
};

export default function AppointmentEventFiles({
  eventFiles,
}: AppointmentEventFilesArgs) {
  const [showMoreFiles, setShowMoreFiles] = useState(false);
  const files = showMoreFiles ? eventFiles : take(eventFiles, 2);

  return (
    <div className="attachments files files-region detail-wrap">
      <strong className="files-title block font-semibold mb-4">
        {I18n.t("js.activity.attachments.files_label")}
      </strong>
      <div>
        {map(files, (file, index) => (
          <FileRow key={index} {...file} />
        ))}
      </div>
      {eventFiles.length > 2 && !showMoreFiles && (
        <div className="show-more flex flex-1 items-center mt-1">
          <span className="fa-layers fa-fw mr-2">
            <i className="fa-regular fa-paperclip-vertical text-gray-400 fa-lg"></i>
          </span>
          <a
            className="grow"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowMoreFiles(true);
            }}
          >
            {eventFiles.length - 2 === 1
              ? I18n.t("js.activity.attachments.show_one_more_file")
              : I18n.t("js.activity.attachments.show_more_files", {
                  file_count: eventFiles.length - 2,
                })}
          </a>
        </div>
      )}
    </div>
  );
}
