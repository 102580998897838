import React, { ReactElement, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { head, isEmpty, map } from "lodash";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { format } from "date-fns";
import {
  ApplicationConfiguration,
  Appointment,
  AttendeeConfiguration,
} from "../../../@types/appointments";
import { Membership } from "../../../@types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Translation from "components/shared/Translation";

function Detail({
  icon,
  type = "",
  children,
  text,
  label,
  translated,
}: {
  icon: IconProp;
  type?: string;
  children?: ReactNode[] | ReactElement;
  text?: string;
  label?: string;
  translated?: boolean;
}) {
  return (
    <div className="detail-wrap">
      <strong>
        {I18n.t(label || `js.calendars.appointment.${type}.label`)}
      </strong>
      <div className={`detail-block ${type}`}>
        <FontAwesomeIcon icon={icon} className="detail-icon" />
        {children ? children : text || ""}
        {translated && (
          <div style={{ color: "grey", fontSize: "x-small" }}>(translated)</div>
        )}
      </div>
    </div>
  );
}

type AppointmentEventDetailsArgs = {
  category: string | null;
  attendeeConfig: AttendeeConfiguration;
  consultants: string;
  applicationConfig: ApplicationConfiguration;
  organizer: string;
  contacts: Membership[];
  attendingParticipantsCount: number;
  teamsParticipantsCount: number;
  createdAt: string;
  genericAuthor?: Appointment["generic_author"];
  author: Appointment["author"];
  lastAuthor: Appointment["last_author"];
  updatedAt: string;
  translated?: boolean;
};

export default function AppointmentEventDetails({
  category,
  attendeeConfig,
  consultants,
  applicationConfig,
  organizer,
  contacts,
  attendingParticipantsCount,
  teamsParticipantsCount,
  createdAt,
  genericAuthor,
  author,
  updatedAt,
  lastAuthor,
  translated,
}: AppointmentEventDetailsArgs) {
  return (
    <div className="event-details">
      {category && (
        <Detail type="category" icon={regular("coffee")} text={category} />
      )}
      {attendeeConfig.total && (
        <Detail type="attendee_configuration" icon={regular("user")}>
          {attendeeConfig.total_attendees_enforced && (
            <>{attendingParticipantsCount} /</>
          )}
          {attendeeConfig.total}
          {teamsParticipantsCount > 0 && (
            <>
              {I18n.t(
                "administration.consumer_manager.teams_participants_list_title",
                {
                  count: teamsParticipantsCount,
                },
              )}
            </>
          )}
        </Detail>
      )}
      {consultants && (
        <Detail
          type="consultants"
          icon={regular("bullhorn")}
          text={consultants}
        />
      )}
      {applicationConfig.registration_with_costs && (
        <Detail
          icon={regular("money-bill")}
          label="js.calendars.application_configuration.costs.label"
        >
          {applicationConfig.costs || 0}{" "}
          {applicationConfig.currency || I18n.t("js.currency")}
        </Detail>
      )}
      {organizer && (
        <Detail
          type="organizer"
          icon={regular("home")}
          text={organizer}
          translated={translated}
        />
      )}
      {applicationConfig.url && (
        <Detail
          icon={regular("external-link")}
          label="js.calendars.application_configuration.ticket_url.label"
        >
          {applicationConfig.url.match(/^https?:\/\//) ? (
            <a
              href={applicationConfig.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {applicationConfig.url}
            </a>
          ) : (
            <span>{applicationConfig.url}</span>
          )}
        </Detail>
      )}
      {applicationConfig.registration_required &&
        applicationConfig.registration_deadline && (
          <Detail
            icon={regular("timer")}
            label="js.calendars.application_configuration.registration_deadline.label"
          >
            <time>
              {format(
                new Date(applicationConfig.registration_deadline),
                "dd.MM.y",
              )}
            </time>
          </Detail>
        )}
      {!isEmpty(contacts) && (
        <Detail icon={regular("phone")} type="contacts">
          {contacts.length === 1 ? (
            <a href={head(contacts)?.path}>{head(contacts)?.name}</a>
          ) : (
            <ul>
              {map(contacts, (contact) => (
                <li key={contact.path}>
                  <a href={contact.path}>{contact.name}</a>
                </li>
              ))}
            </ul>
          )}
        </Detail>
      )}
      {createdAt && (
        <Detail
          label="js.calendars.appointment.creation"
          icon={regular("pencil-square")}
        >
          <Translation
            component="span"
            translation={
              genericAuthor?.path || author?.path
                ? "js.calendars.appointment.created_at_from_html"
                : "js.calendars.appointment.created_at_from_without_link"
            }
            variables={
              genericAuthor?.path || author?.path
                ? {
                    at_time: format(new Date(createdAt), "dd.MM.y hh:mm"),
                    actor_url: genericAuthor?.path || author?.path || "",
                    actor_name:
                      genericAuthor?.name ||
                      `${author?.name}${
                        author?.detection_profile_field
                          ? ` (${author?.detection_profile_field})`
                          : ""
                      }`,
                  }
                : {
                    at_time: format(new Date(createdAt), "dd.MM.y hh:mm"),
                    actor_name:
                      genericAuthor?.name ||
                      `${author?.name}${
                        author?.detection_profile_field
                          ? ` (${author?.detection_profile_field})`
                          : ""
                      }`,
                  }
            }
          />
          <br />
          <strong>{I18n.t("js.calendars.appointment.last_edit")}</strong>
          <br />
          {format(new Date(updatedAt), "dd.MM.y hh:mm ")}
          {lastAuthor && (
            <Translation
              component="span"
              translation={
                lastAuthor.path
                  ? "js.calendars.appointment.updated_from_html"
                  : "js.calendars.appointment.updated_from_without_link"
              }
              variables={
                lastAuthor.path
                  ? {
                      url: lastAuthor.path,
                      name: `${lastAuthor.name}${
                        lastAuthor.detection_profile_field
                          ? ` (${lastAuthor.detection_profile_field})`
                          : ""
                      }`,
                    }
                  : {
                      name: `${lastAuthor.name}${
                        lastAuthor.detection_profile_field
                          ? ` (${lastAuthor.detection_profile_field})`
                          : ""
                      }`,
                    }
              }
            />
          )}
        </Detail>
      )}
    </div>
  );
}
