import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { currentMemberSelector } from "selectors/environment";
import DropDown, {
  DropDownItem,
  DropDownSubMenu,
} from "components/shared/DropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

interface TranslationDropDownProps {
  handleLanguageSelect: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: string,
  ) => void;
  object_language: string;
}

interface Language {
  language: string;
  name: string;
  supports_formality: boolean;
}

export const ORIGINAL_LANGUAGE_SIGNIFIER = "ORIGINAL";

export default function TranslationDropDown({
  handleLanguageSelect,
  object_language,
}: TranslationDropDownProps) {
  const currentMember = useSelector(currentMemberSelector);
  const { data: languages } = useQuery<Language[]>("/translations/languages", {
    refetchOnWindowFocus: false,
  });
  const [primaryLanguages, setPrimaryLanguages] = useState<Language[]>([]);
  const [secondaryLanguages, setSecondaryLanguages] = useState<Language[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState(
    object_language || ORIGINAL_LANGUAGE_SIGNIFIER,
  );

  function categorizeLanguages() {
    const primaryLanguageKeys = [
      currentMember?.last_used_translation_language,
      "DE",
      "EN-GB",
      "ES",
      "FR",
    ];
    const primary =
      languages
        ?.filter((lang) => primaryLanguageKeys.includes(lang.language))
        .sort(
          (a, b) =>
            primaryLanguageKeys.indexOf(a.language) -
            primaryLanguageKeys.indexOf(b.language),
        ) || [];

    const secondary =
      languages?.filter(
        (lang) => !primaryLanguageKeys.includes(lang.language),
      ) || [];

    return [primary, secondary];
  }

  useEffect(() => {
    const [primary, secondary] = categorizeLanguages();
    setPrimaryLanguages(primary);
    setSecondaryLanguages(secondary);
  }, [languages, selectedLanguage, currentMember]);

  function renderLanguageListItem(lang: Language) {
    return (
      <a
        className={classNames("dropdown-item translate-menu-item", {
          disabled: selectedLanguage === lang.language,
        })}
        href="#"
        onClick={(event) => {
          event.preventDefault();
          handleLanguageSelect(event, lang.language);
          setSelectedLanguage(lang.language);
        }}
      >
        {lang.name}
      </a>
    );
  }

  return (
    <div className="translation-controls">
      <a
        className="translate-button btn btn-sm text-muted dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-label="Translate"
      >
        <FontAwesomeIcon icon={light("language")} />
      </a>
      <DropDown>
        {selectedLanguage !== ORIGINAL_LANGUAGE_SIGNIFIER && (
          <DropDownItem>
            <a
              className="dropdown-item translate-menu-item"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                handleLanguageSelect(event, ORIGINAL_LANGUAGE_SIGNIFIER);
                setSelectedLanguage(ORIGINAL_LANGUAGE_SIGNIFIER);
              }}
            >
              {I18n.t(
                "js.activitystreams.activity.translation_controls.show_original",
              )}
            </a>
          </DropDownItem>
        )}
        {primaryLanguages.map((lang) => (
          <DropDownItem key={lang.language}>
            {renderLanguageListItem(lang)}
          </DropDownItem>
        ))}
        {secondaryLanguages.length > 0 && (
          <DropDownSubMenu
            text={I18n.t(
              "js.activitystreams.activity.translation_controls.more",
            )}
            side={window.isApp ? "left" : "right"}
          >
            {secondaryLanguages.map((lang) => (
              <DropDownItem key={lang.language}>
                {renderLanguageListItem(lang)}
              </DropDownItem>
            ))}
          </DropDownSubMenu>
        )}
      </DropDown>
    </div>
  );
}
