import React from "react";
import PermissionPicker from "components/shared/PermissionPicker";

export default function ChannelsPage() {
  return (
    <div>
      Channels ✨
      <div className={"mt-4"}>
        <h5>PermissionPicker Test, einfach ignorieren</h5>
        <PermissionPicker />
      </div>
    </div>
  );
}
