import React from "react";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import { forEach } from "lodash";
import TranslationDropDown, {
  ORIGINAL_LANGUAGE_SIGNIFIER,
} from "./TranslationDropDown";

interface TranslationControlsProps {
  object_language: string;
  types: string[];
  target_id: string;
  resetTranslation: (language: string) => void;
  translateObject: (content: string, language: string, type?: string) => void;
}

export default function TranslationControls({
  types,
  target_id,
  object_language,
  resetTranslation,
  translateObject,
}: TranslationControlsProps) {
  const dispatch: Dispatch = useDispatch();

  function handleLanguageSelect(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: string,
  ) {
    event.preventDefault();
    dispatch({
      type: "currentMember/LANGUAGE/SET_LAST_USED",
      payload: language,
    });

    forEach(types, (type) => translate(language, type));
  }

  function translate(targetLanguage: string, type: string) {
    if (targetLanguage === ORIGINAL_LANGUAGE_SIGNIFIER) {
      resetTranslation(targetLanguage);
    } else {
      fetch(`/translations/${targetLanguage}/${type}/${target_id}`)
        .then((response) => response.json())
        .then((data) => {
          translateObject(data.text, targetLanguage, type);
        })
        .catch((e) => {
          console.error(e);
          toastr.error(
            I18n.t("js.activitystreams.activity.translation_controls.error"),
          );
        });
    }
  }

  return (
    <TranslationDropDown
      handleLanguageSelect={handleLanguageSelect}
      object_language={object_language}
    />
  );
}
