import React from "react";
import {
  MembershipRole,
  PermissionPickableWithDisabled,
  SystemRole,
} from "components/shared/DirectoryPicker/types";
import DirectoryPicker from "components/shared/DirectoryPicker";
import { includes } from "lodash";

/**
 * UI element analogous to the selection of memberships in the Appointment-Create form (invitations) to assign the following users/roles to an action:
 *
 *  - Network administrators
 *  - Groups (all members)
 *  - Groups (administrators only)
 *  - Groups (membership role)
 *  - Network-wide memberships role
 *  - Individual memberships
 */
export default function PermissionPicker(props) {
  return (
    <DirectoryPicker
      {...props}
      placeholder={I18n.t("js.permission_picker.placeholder")}
      multi
      extraOptions={extraOptions}
    />
  );
}

async function extraOptions(
  inputValue: string,
): Promise<PermissionPickableWithDisabled[]> {
  return [...systemRoles(inputValue), ...networkMembershipRoles(inputValue)];
}

function systemRoles(inputValue: string): PermissionPickableWithDisabled[] {
  const systemRoles: SystemRole[] = [
    {
      type: "system_role",
      id: "network_admin",
      name: I18n.t("js.permission_picker.system_roles.network_admin"),
    },
  ];
  return filterByInputValue(systemRoles, inputValue);
}
export function networkMembershipRoles(
  inputValue: string,
): PermissionPickableWithDisabled[] {
  const membershipRoles: MembershipRole[] =
    Preload.current_network.membership_roles.map((r) => ({
      ...r,
      type: "membership_role",
    }));

  return filterByInputValue(membershipRoles, inputValue);
}

function filterByInputValue<T extends { name: string }>(
  items: T[],
  inputValue: string,
): T[] {
  return items.filter((r) =>
    includes(r.name.toLowerCase(), inputValue.toLowerCase()),
  );
}
