import parseHeader from "./parseHeader";
import { isEmpty } from "lodash";

export default async function fetchPaginatedData({ pageParam }) {
  const response = await fetch(pageParam);
  const data = await response.json();

  const linkHeader = response.headers.get("Link");
  const nextUrl = parseHeader(linkHeader).next;

  return {
    data,
    nextPage: isEmpty(nextUrl) ? null : nextUrl,
  };
}
