import React, { Fragment } from "react";
import { useStore, useSelector } from "react-redux";
import { Field, formValues } from "redux-form";
import { selectNetworkId } from "selectors/environment";
import persistItem from "helpers/items/persistItem";
import MembershipForm from "components/memberships/MembershipForm";
import ControlGroup from "../shared/fields/ControlGroup";
import Hint from "../shared/fields/Hint";
import EmailField from "../appCreator/properties/edit/EmailField";
import CheckboxField from "../shared/fields/CheckboxField";
import { useLoadApp } from "components/appCreator/api";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

const MemberCreateFields = formValues("send_welcome_mail")((props) => {
  const { send_welcome_mail } = props as React.PropsWithChildren<
    { send_welcome_mail?: boolean } & string
  >;
  return (
    <Fragment>
      <Hint hint={I18n.t("js.administration.members.new.hint")} />

      <ControlGroup
        name="email"
        label={I18n.t("js.administration.members.new.email")}
        htmlFor="new-membership-email"
        required
      >
        <EmailField name="email" id="new-membership-email" required />
      </ControlGroup>

      <ControlGroup>
        <CheckboxField
          name="send_welcome_mail"
          label={I18n.t("js.administration.members.new.user_chooses_pwd")}
        />
        <Hint hint={I18n.t("js.administration.members.new.help")} />
      </ControlGroup>

      {!send_welcome_mail ? (
        <ControlGroup
          name="password"
          label={I18n.t("js.administration.members.new.password")}
          htmlFor="new-membership-email"
          required
        >
          <Field
            name="password"
            id="new-membership-password"
            type="password"
            component="input"
            required
          />
        </ControlGroup>
      ) : null}
      <hr />
    </Fragment>
  );
}) as React.ComponentClass<unknown>;

function MembershipNew() {
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const store = useStore();
  const { data: app } = useLoadApp({
    appId: undefined,
    externalAppId,
  });
  const { mutate: createMembership } = useMutation(
    ({
      body,
    }: {
      body: {
        user: {
          email: string;
          password: string;
          send_welcome_mail: boolean;
          profile_id: string;
        };
      };
    }) =>
      fetchApi("/administration/members", {
        method: "POST",
        body,
      }),
    {
      onSuccess: () => {
        location.replace(`/administration/members`);
        toastr.success(I18n.t("js.saving_successful"));
      },
      onError: () => {
        toastr.error(I18n.t("js.saving_failure"));
      },
    },
  );

  const onSubmit = async (values) => {
    const { email, password, send_welcome_mail, ...profile } = values;
    const { id: itemId } = await persistItem(app, store.dispatch, {
      externalAppId,
      values: profile,
    });

    return createMembership({
      body: {
        user: {
          email,
          password,
          send_welcome_mail,
          profile_id: itemId,
        },
      },
    });
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      onSubmit={onSubmit}
      title={I18n.t("js.administration.members.new.page_title")}
      renderExtraFields={() => <MemberCreateFields />}
      initialValues={{ send_welcome_mail: true }}
    />
  );
}

export default MembershipNew;
