import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import BlogEditPage from "components/blogs/BlogEditPage";

const blogRoutes: RouteObject[] = [
  {
    path: "/blogs/:id/edit",
    element: <BlogEditPage />,
  },
];

export default blogRoutes;
