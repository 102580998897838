import React, { useEffect } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import Select from "components/shared/form/fields/Select";
import { useQuery } from "react-query";
import { find, isString, map } from "lodash";
import { Categories } from "../../../@types/appointmentCategories";

export default function CategorySelectionField({
  control,
  setValue,
}: {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}) {
  const { data: categories } = useQuery<Categories>("/appointments/categories");
  const selectedCategory = useWatch({ control, name: "category" });

  useEffect(() => {
    if (categories && isString(selectedCategory)) {
      const preSelectedCategory = find(categories, { label: selectedCategory });

      preSelectedCategory &&
        setValue("category", {
          label: preSelectedCategory.label,
          value: preSelectedCategory.id,
        });
    }
  }, [categories]);

  return (
    <Select
      name="category"
      label={I18n.t("js.calendars.appointment.category.label")}
      options={map(categories, (cat) => ({
        value: cat.id,
        label: cat.label,
      }))}
      control={control}
    />
  );
}
