import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ChangeHandler } from "react-hook-form";

type TimePickerArgs = {
  value: string;
  onChange: (value: string) => void | ChangeHandler;
};

export default function TimePicker({
  value = moment().format(),
  onChange,
}: TimePickerArgs) {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(moment(value));
  const [inputValue, setInputValue] = useState(
    moment(value).format("HH:mm") || moment().format("HH:mm"),
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedDateTime(moment(value)); // update date if its changed from outside (e.g. datepicker)
  }, [value]);

  useEffect(() => {
    if (!selectedDateTime.isSame(moment(value))) {
      onChange(moment(selectedDateTime).format());
    }
  }, [selectedDateTime]);

  function handleClickOutside(event: MouseEvent) {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setShowPicker(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function increaseHour(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();

    const newDateTime = moment(selectedDateTime).add(1, "hour");
    setSelectedDateTime(newDateTime);
    setInputValue(newDateTime.format("HH:mm"));
  }

  function decreaseHour(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();

    const newDateTime = moment(selectedDateTime).subtract(1, "hour");
    setSelectedDateTime(newDateTime);
    setInputValue(newDateTime.format("HH:mm"));
  }

  function increaseMinutes(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();

    const minutes = selectedDateTime.minutes();
    const roundedMinutes = Math.floor(minutes / 5) * 5;

    selectedDateTime.minutes(roundedMinutes);

    const newDateTime = moment(selectedDateTime)
      .startOf("minute")
      .add(5, "minutes");
    setSelectedDateTime(newDateTime);
    setInputValue(newDateTime.format("HH:mm"));
  }

  function decreaseMinutes(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();

    const minutes = selectedDateTime.minutes();
    const roundedMinutes = Math.ceil(minutes / 5) * 5;

    selectedDateTime.minutes(roundedMinutes);

    const newDateTime = moment(selectedDateTime).subtract(5, "minutes");
    setSelectedDateTime(newDateTime);
    setInputValue(newDateTime.format("HH:mm"));
  }

  function parseTime(value: string) {
    const [inputHours, inputMinutes] = value.split(":");
    let hours = "00";
    let minutes = "00";

    if (/^[0-2]?[0-9]$/.test(inputHours)) {
      hours = inputHours.padStart(2, "0");
      if (parseInt(hours, 10) > 23) {
        hours = "00";
      }
    }

    if (/^[0-5]?[0-9]$/.test(inputMinutes)) {
      minutes = inputMinutes.padStart(2, "0");
    }

    return `${hours}:${minutes}`;
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }
  function handleBlur() {
    const newTime = parseTime(inputValue);

    const [newHours, newMinutes] = newTime.split(":");

    const updatedDateTime = moment(selectedDateTime)
      .hours(parseInt(newHours, 10))
      .minutes(parseInt(newMinutes, 10));

    setSelectedDateTime(updatedDateTime);
    setInputValue(updatedDateTime.format("HH:mm"));
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const input = e.target as HTMLInputElement;
    const { selectionStart, selectionEnd } = input;

    if (selectionStart === null || selectionEnd === null) {
      return;
    }

    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();

      if (selectionStart < 3) {
        if (e.key === "ArrowUp") {
          increaseHour();
        } else if (e.key === "ArrowDown") {
          decreaseHour();
        }
      } else {
        if (e.key === "ArrowUp") {
          increaseMinutes();
        } else if (e.key === "ArrowDown") {
          decreaseMinutes();
        }
      }

      setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.selectionStart = selectionStart;
          inputRef.current.selectionEnd = selectionEnd;
        }
      }, 0);
    }
  }

  return (
    <div className="w-28" ref={containerRef}>
      <div className="input-group w-28">
        <input
          ref={inputRef}
          value={inputValue}
          type="text"
          onFocus={() => setShowPicker(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={I18n.t("js.calendars.date_range.time_placeholder")}
        />
        <button
          className="btn btn-light btn-sm"
          onClick={(e) => {
            e.preventDefault();
            inputRef?.current?.focus();
          }}
        >
          <FontAwesomeIcon icon={regular("clock")} />
        </button>
      </div>
      {showPicker && (
        <div className="border-box absolute mt-1 p-3 z-20">
          <div className="grid grid-cols-3 grid-rows-[1fr_25px_1fr] gap-y-2">
            <button className="btn btn-sm btn-light" onClick={increaseHour}>
              <FontAwesomeIcon icon={solid("chevron-up")} />
            </button>
            <div />
            <button className="btn btn-sm btn-light" onClick={increaseMinutes}>
              <FontAwesomeIcon icon={solid("chevron-up")} />
            </button>
            <div className="text-center align-baseline">
              {selectedDateTime.format("HH")}
            </div>
            <div className="text-center align-baseline">:</div>
            <div className="text-center align-baseline">
              {selectedDateTime.format("mm")}
            </div>
            <button className="btn btn-sm btn-light" onClick={decreaseHour}>
              <FontAwesomeIcon icon={solid("chevron-down")} />
            </button>
            <div />
            <button className="btn btn-sm btn-light" onClick={decreaseMinutes}>
              <FontAwesomeIcon icon={solid("chevron-down")} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
