import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import classnames from "classnames";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type DropDownArgs = {
  children: React.ReactNode;
  alignment?: "start" | "end";
  className?: string;
};

function DropDown({ children, className, alignment = "end" }: DropDownArgs) {
  return (
    <ul
      className={classnames(
        `dropdown-menu dropdown-menu-${alignment} empty:hidden`,
        className,
      )}
    >
      {children}
    </ul>
  );
}

type DropDownItemArgs = {
  children?: React.ReactNode;
  url?: string;
  icon?: IconProp;
  text?: string;
  additional?: React.ReactNode;
  linkStyle?: string;
  iconStyle?: React.CSSProperties;
  method?: string;
  confirm?: string;
  className?: string;
  iconClassName?: string;
  onSelect?: MouseEventHandler<HTMLAnchorElement>;
  title?: string;
  dividerTop?: boolean;
  dividerBottom?: boolean;
};

export function DropDownItem({
  children,
  url,
  icon,
  text,
  additional,
  linkStyle,
  iconStyle,
  method,
  confirm,
  className,
  iconClassName,
  onSelect,
  title,
  dividerTop,
  dividerBottom,
}: DropDownItemArgs) {
  return (
    <>
      {dividerTop && <li className="divider" />}
      <li className={className} title={title || text}>
        {children ? (
          children
        ) : (
          <a
            href={url || "#"}
            className={classNames("dropdown-item", linkStyle)}
            data-method={method}
            data-confirm={confirm}
            onClick={onSelect}
          >
            <span className="flex items-center space-x-1">
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className={classNames("fa-fw", iconClassName)}
                  style={iconStyle}
                />
              )}
              {text && <span className="flex-1">{text}</span>}
              {additional}
            </span>
          </a>
        )}
      </li>
      {dividerBottom && <li className="divider" />}
    </>
  );
}

type DropDownSubMenuArgs = {
  text: string;
  children: React.ReactNode;
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  side?: "left" | "right";
  dividerTop?: boolean;
  dividerBottom?: boolean;
};

export function DropDownSubMenu({
  text,
  children,
  className,
  icon,
  side = "right",
  dividerTop,
  dividerBottom,
}: DropDownSubMenuArgs) {
  return (
    <>
      {dividerTop && <li className="divider" />}
      <li className={classnames("relative group", className)}>
        <div className="dropdown-item flex items-center w-full text-left cursor-pointer">
          <span className="flex items-center space-x-1">
            {icon && <FontAwesomeIcon icon={icon} className="fa-fw" />}
            <span className="flex-1">{text}</span>
          </span>
          <FontAwesomeIcon
            icon={regular("chevron-right")}
            className="ml-auto"
          />
        </div>
        <ul
          className={classNames(
            "dropdown-menu absolute top-0 mt-0 hidden group-hover:block",
            {
              "left-full ml-1": side == "right",
              "right-full mr-1": side == "left",
            },
          )}
        >
          {children}
        </ul>
      </li>
      {dividerBottom && <li className="divider" />}
    </>
  );
}

DropDown.Item = DropDownItem;
DropDown.SubMenu = DropDownSubMenu;
export default DropDown;
